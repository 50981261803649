import { Text, VStack } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

function SuccessPage() {
  return (
    <VStack
      backgroundColor="#F7F9FF"
      alignItems="center"
      px={12}
      pb={20}
      minHeight="100vh"
    >
      <VStack textAlign="center" mt={24} maxWidth="600px">
        <CheckCircleIcon color="#017C51" height="80px" width="80px" />
        <Text
          mt={3}
          w="80%"
          color="#017C51"
          fontSize="28px"
          fontWeight={800}
          lineHeight="120%"
        >
          Thank you for your response
        </Text>
        ;
        <Text mt={6} lineHeight="140%">
          Thank you for filling out the registration form. Our staff will
          carefully review the information provided and make preparations for
          your child to begin classes with us. If you have any further
          questions, please don't hesitate to contact us via WhatsApp. We will
          get back to you regarding the application as soon as possible.
        </Text>
        ;
      </VStack>
    </VStack>
  );
}

export default SuccessPage;
