import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  SlideFade,
  VStack,
} from "@chakra-ui/react";

import PagedFlowLayout from "./PagedFlowLayout";
import PagedFlowLayoutHeader from "./PagedFlowLayoutHeader";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { MSDatePicker } from "./MSDatePicker";
import PagedFlowLayoutFormSection from "./PagedFlowLayoutFormSection";
import PagedFlowLayoutNavSection from "./PagedFlowLayoutNavSection";
import { RegistrationFormProps } from "../../RegistrationForm";
import { MSPhoneInput } from "./MSPhoneInput";
import _ from "lodash";
import { nationalities } from "../constants/nationalities";

type StudentInfoProps = {
  studentFullName: string;
  studentDob: Date;
  studentEmail?: string;
  studentMobileNumber?: string;
  studentGender: string;
  studentNationality: string;
  studentEthnicity: string;
};

const schema = z.object({
  studentFullName: z.string(),
  studentDob: z.date(),
  studentEmail: z
    .string()
    .email({ message: "Please enter a valid email address" })
    .or(z.literal("")), // only check email if not empty string
  studentMobileNumber: z
    .string()
    .refine(
      (val) => {
        if (_.startsWith(val, "+65")) {
          const eightDigitsLong = val.length === 11; // includes country code
          const startsWithEightOrNine =
            _.startsWith(val, "+658") || _.startsWith(val, "+659");
          return eightDigitsLong && startsWithEightOrNine;
        }
        return true;
      },
      { message: "Please enter a valid phone number." }
    )
    .optional(), // .optional() is needed because default value for phone number is undefined
  studentGender: z.string(),
  studentNationality: z.string(),
  studentEthnicity: z.string(),
});

function StudentInfo({
  currPage,
  pageInFlow,
  nextPage,
  prevPage,
  registrationData,
  updateRegistration,
}: {
  currPage: number;
  pageInFlow: number;
  nextPage: () => void;
  prevPage: () => void;
  registrationData: RegistrationFormProps;
  updateRegistration: (pageData: any) => void;
}) {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    resetField,
  } = useForm<StudentInfoProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...registrationData,
    },
  });

  const onSubmit = async (data: StudentInfoProps) => {
    // Handle form submission here
    console.log("date received", data);

    // TODO: Handle updating parent object
    updateRegistration(data);

    nextPage();
  };

  return (
    <PagedFlowLayout>
      <PagedFlowLayoutHeader
        title="Student Information"
        subtitle="Step 2 of 4"
      />
      <SlideFade in={currPage === pageInFlow} offsetX="100px" offsetY="0px">
        <PagedFlowLayoutFormSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              gap={5}
              alignItems={"flex-start"}
              maxWidth={{ base: "100%", lg: "50%" }}
            >
              <FormControl isInvalid={!!errors.studentFullName} isRequired>
                <FormLabel htmlFor="name">Full Name</FormLabel>
                <Input id="name" {...register("studentFullName")} />
                {errors.studentFullName && (
                  <FormErrorMessage>
                    {errors.studentFullName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentDob} isRequired>
                <FormLabel htmlFor="studentDob">Date of Birth</FormLabel>
                <Controller
                  control={control}
                  name="studentDob"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <MSDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={20}
                    />
                  )}
                />
                {errors.studentDob && (
                  <FormErrorMessage>
                    {errors.studentDob.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentEmail}>
                <FormLabel htmlFor="studentEmail">Email</FormLabel>
                <Input id="studentEmail" {...register("studentEmail")} />
                {errors.studentEmail && (
                  <FormErrorMessage>
                    {errors.studentEmail.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentMobileNumber}>
                <FormLabel htmlFor="studentMobileNumber">
                  Mobile Number
                </FormLabel>
                <Controller
                  control={control}
                  name="studentMobileNumber"
                  render={({ field: { onChange, value } }) => (
                    <MSPhoneInput
                      id="studentMobileNumber"
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                      }} // map the component onChange to use the react-hook-form onChange
                    />
                  )}
                />
                {errors.studentMobileNumber && (
                  <FormErrorMessage>
                    {errors.studentMobileNumber.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentGender} isRequired>
                <FormLabel htmlFor="studentGender">Gender</FormLabel>
                <RadioGroup defaultValue="Male" display="flex" gap={5}>
                  <Radio value="Male" {...register("studentGender")}>
                    Male
                  </Radio>
                  <Radio value="Female" {...register("studentGender")}>
                    Female
                  </Radio>
                </RadioGroup>
                {errors.studentGender && (
                  <FormErrorMessage>
                    {errors.studentGender.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentNationality} isRequired>
                <FormLabel htmlFor="studentNationality">Nationality</FormLabel>
                <Select
                  id="studentNationality"
                  placeholder="Select Nationality"
                  defaultValue="Singaporean"
                  {...register("studentNationality")}
                >
                  {nationalities.map((nationality) => (
                    <option key={nationality} value={nationality}>
                      {nationality}
                    </option>
                  ))}
                </Select>
                {errors.studentNationality && (
                  <FormErrorMessage>
                    {errors.studentNationality.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentEthnicity} isRequired>
                <FormLabel htmlFor="studentEthnicity">Ethnicity</FormLabel>
                <Select
                  id="studentEthnicity"
                  placeholder="Select Ethnicity"
                  {...register("studentEthnicity")}
                >
                  <option value="Chinese">Chinese</option>
                  <option value="Malay">Malay</option>
                  <option value="Indian">Indian</option>
                  <option value="Others">Others</option>
                </Select>
                {errors.studentEthnicity && (
                  <FormErrorMessage>
                    {errors.studentEthnicity.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
            <PagedFlowLayoutNavSection>
              <Button
                variant="ms-outline"
                onClick={prevPage}
                leftIcon={<ArrowBackIcon pt="1px" />}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="ms-solid"
                rightIcon={<ArrowForwardIcon pt="1px" />}
              >
                Continue
              </Button>
            </PagedFlowLayoutNavSection>
          </form>
        </PagedFlowLayoutFormSection>
      </SlideFade>
    </PagedFlowLayout>
  );
}

export default StudentInfo;
