import { Box, Button, Center, SlideFade } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";

function PagedFlow({
  page,
  children,
}: {
  page: number;
  children: React.ReactNode;
}) {
  if (!Array.isArray(children)) {
    throw new Error("PagedFlow children must be an array");
  }

  return (
    <Center>
      <Box px={{ base: 6, md: 8 }} w="100%" maxWidth="1200px">
        {children.map(
          (child, index) => page === index && <Box key={index}>{child}</Box>
        )}
      </Box>
    </Center>
  );
}

export default PagedFlow;
