import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  VStack,
  InputProps,
} from "@chakra-ui/react";
import Fuse from "fuse.js";

const AutocompleteInput = ({
  suggestions,
  setInputValue,
  inputProps,
}: {
  suggestions: string[];
  setInputValue: (value: string) => void;
  inputProps: InputProps;
}) => {
  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const fuse = new Fuse(suggestions, {
    minMatchCharLength: 3,
    findAllMatches: false,
    threshold: 0.2, // 0 is perfect match, 1 is match everything
    distance: 5, // how many characters the match can appear from actual location
  });

  useEffect(() => {
    if (query) {
      const filtered = fuse.search(query).map((result) => result.item);
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }, [query, suggestions]);

  const handleFocus = (_: any) => {
    setShowSuggestions(true);
  };

  const handleBlur = (_: any) => {
    // Adding a small timeout to let suggestion get committed if it's clicked
    setTimeout(() => {
      // setShowSuggestions(false);
    }, 100);
  };

  const handleChange = (event: any) => {
    const { onChange: inputOnChange } = inputProps;

    setQuery(event.target.value);
    if (inputOnChange) inputOnChange(event);
  };

  const handleSuggestionClick = (suggestion: string) => {
    // Set query to empty so that search only happens if user changes value
    setQuery("");
    setShowSuggestions(false);

    // Set value of input to the selected suggestion
    setInputValue(suggestion);
  };

  return (
    <Box position="relative" width="100%">
      <Input
        {...inputProps}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder="Enter school name"
        autoComplete="off"
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <Box
          position="absolute"
          top="120%"
          left="0"
          right="0"
          bg="white"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          zIndex="dropdown"
          maxH="300px"
          overflow="scroll"
        >
          <List spacing={1}>
            {filteredSuggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                cursor="pointer"
                _hover={{ bg: "gray.100" }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {/* Padding inside so that whole ListItem is clickable */}
                <Box padding={3}>{suggestion}</Box>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default AutocompleteInput;
