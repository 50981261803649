import { Box, Center } from "@chakra-ui/react";

import MsLogo from "../assets/ms-logo-colour.jpg";

function FormHeader() {
  return (
    <Box pt={8} pb={4} borderBottom="1px solid #D9D9D9">
      <Center>
        <img src={MsLogo} alt="ms logo" width="150px" />
      </Center>
    </Box>
  );
}

export default FormHeader;
