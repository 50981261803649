import React from "react";
import PhoneInput, {
  DefaultInputComponentProps,
  Props as PhoneInputProps,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import "./MSPhoneInput.css";
import { InputGroup } from "@chakra-ui/react";

export function MSPhoneInput(
  props: PhoneInputProps<DefaultInputComponentProps>
) {
  return (
    // Wrapping in input group so that PhoneInput has access to Chakra variables
    <InputGroup flexDirection="column">
      <PhoneInput
        international={false} // does not show +65 in input field
        defaultCountry="SG"
        withCountryCallingCode={false}
        {...props}
      />
    </InputGroup>
  );
}
