import React from "react";
import "./TermsAndConditionsPage.css";

export const TermsAndConditionsPage = () => (
  <div className="terms-main">
    <h1>Terms & Conditions for Registration of MS Courses</h1>

    <p>
      I, the parent / guardian (the "Parent") of the student (the "Student"),
      hereby agree to the following terms and conditions (the "General Terms"):
    </p>

    <h2>Interpretation</h2>
    <ol>
      <li>
        In these Terms & Conditions, the following words and phrases shall bear
        the following meanings, unless the context requires otherwise:
        <ol type="a">
          <li>
            <span className="bold">"Academic Year"</span> means an academic year
            at the Centre, which generally runs a specified start date in Nov to
            31 October the following year;
          </li>
          <li>
            <span className="bold">"Calendar Month"</span> means a full month
            commencing on the 1st day of a calendar month and ending on the last
            day of that calendar month; and
          </li>
          <li>
            <span className="bold">
              "Mind Stretcher", "Centre", "MS" or "We"
            </span>{" "}
            means any entity which runs tuition / enrichment / academic
            programmes / workshops / seminars/ all other programmes or events
            under the "Mind Stretcher", "Mind Stretcher Learning Centre", "MS
            Junior Campus", "Mind Stretcher Junior Campus", "Xue Hui Le",
            "学汇乐", "Master Speaker" names or any of their variations thereof
            in Singapore.
          </li>
        </ol>
      </li>
    </ol>

    <h2>Conditions of Registration & Enrolment</h2>
    <ol start={2}>
      <li>
        The enrolment of the Student in the course(s) indicated on the Course
        Registration Form (the{" "}
        <span className="bold">"Registered Course(s)"</span>) is conditional
        upon, inter alia:
        <ol type="a">
          <li>payment of a registration fee (if applicable);</li>
          <li>
            payment of a deposit equivalent to 1 month of course fees, unless
            otherwise specified, for the Registered Course(s) (the{" "}
            <span className="bold">"Deposit"</span>), together with the
            applicable Goods & Services Tax (<span className="bold">"GST"</span>
            ) or any other tax that may be levied by the Government of Singapore
            from time to time, upon the registration of the Student's enrolment
            in the Registered Course(s). For the avoidance of doubt, and subject
            to these General Terms, the Deposit shall be used to pay for any
            outstanding amounts owed to Mind Stretcher, which shall include but
            is not limited to the course fees for the final month of the
            Registered Course(s), if applicable;
          </li>
          <li>
            purchase of prescribed books for the Registered Course(s), if
            applicable;
          </li>
          <li>
            advance payment of the monthly or termly course fee(s) for all
            Registered Course(s) before the 1<sup>st</sup> day of each Calendar
            Month; and,
          </li>
          <li>
            in the event that course fee(s) remain(s) unpaid at the end of the
            Calendar Month in which they are due for payment, we reserve the
            right to disallow the Student from attending any class(es) in the
            Registered Course(s) from the following month.
          </li>
        </ol>
      </li>
      <li>
        Notwithstanding anything in these General Terms, the Student is not
        entitled to receive any Mind Stretcher curriculum materials (in whatever
        form on whatever media) for any Registered Courses which he or she has
        not paid for. In the event that the Student is registered for any of the
        Registered Course(s) after an Academic Year / term has begun, the
        Student shall be entitled to receive the curriculum materials only from
        the date on which he or she begins attending the Registered Course(s).
      </li>
      <li>
        Classes will not be held on the eve of Chinese New Year and any other
        public holiday which may be gazetted by the Government of Singapore from
        time to time.{" "}
        <span className="bold">
          No make-up classes will be scheduled and no refund of course fees will
          be given in lieu of any classes which would otherwise have been held
          on the aforementioned days.
        </span>{" "}
        Nevertheless, the worksheets for these public holidays will still be
        given out for students' completion, and will be marked by the teachers
        and covered in the subsequent weeks.
      </li>
      <li>
        <span className="bold">
          There is no refund on any part of the monthly / termly course fees in
          the event that the Student fails to attend any class(es) in the
          Registered Course(s) for any reason whatsoever.
        </span>{" "}
        Subject to these General Terms, and in the event that the Student does
        not turn up for class(es) and the fees for that month / term remain
        unpaid, the Deposit shall be used to offset any unpaid fees. Any balance
        remaining after this offset of Deposit remains payable to us.
      </li>
    </ol>

    <h2>Make-Up Classes</h2>
    <ol start={6}>
      <li>
        In the event that the Student fails to attend a class in any of our
        courses (with the exception of Phonics & Reading courses offered under
        MS Junior Campus), Mind Stretcher may at its sole discretion, allow the
        Student to make-up the missed lesson by attending another on-going class
        within the same Centre during the same academic week (i.e. from Monday
        to Sunday), PROVIDED THAT:
        <ol type="a">
          <li>
            there is more than 1 class of the same level, banding (A/HA/GEP) and
            subject within that Centre;
          </li>
          <li>
            there is a vacancy in the class that the Student intends to attend
            as the make-up class; and
          </li>
          <li>
            MS can and is in a position to conduct make-up classes (e.g during
            the COVID-19 pandemic or certain other scenario, there are
            restrictions which render make-up classes impossible)
          </li>
        </ol>
      </li>
      <li>
        In the event that it is not possible to arrange a make-up class due to
        no vacancy in other class(es),{" "}
        <span className="bold">
          the course fee(s) for the relevant Registered Course shall not be
          refunded or pro-rated, even if the Student has valid reason(s) for
          missing the class.
        </span>
      </li>
      <li>
        <span className="bold">
          There will be no make-up classes or refund of fees if the Centre
          closes or there is a closure of classes due to an outbreak of
          contagious diseases (e.g. COVID-19) or other unforeseen circumstances
          beyond our control.
        </span>
      </li>
    </ol>

    <h2>Withdrawals & Refunds</h2>
    <ol start={9}>
      <li>
        A written notice of withdrawal must be given at least one Calendar Month
        before the Student's withdrawal from any of the Registered Course(s),
        and the Deposit shall be used to offset the course fees due for the
        relevant notice period, if applicable. In the event that less than one
        Calendar Month notice is given, no part of the Deposit will be refunded.
      </li>
      <li>
        In the event that a Parent decides to withdraw the student (regardless
        of reasons) before the Registered Course(s) commence(s), the Deposit
        will be forfeited. The one Calendar Month written notice in Clause 9
        will not be applicable in this instance.
      </li>
      <li>
        <span className="bold">
          Unless otherwise stated, all monies paid are non-refundable and
          non-transferrable to another student, regardless of whether the
          Registered Course(s) has / have commenced.
        </span>
      </li>
    </ol>

    <h2>Transfers</h2>
    <ol start={12}>
      <li>
        A written request for transfer must be given at least one Calendar Month
        before the transfer from one course to another, or from one MS centre to
        another. Any transfer can only take effect on the first lesson of the
        following calendar month. In the event that we are unable to accommodate
        this request due to operational reasons or otherwise, and the Parent
        decides to withdraw the Student from the relevant Registered Course(s)
        or centre, the aforementioned written request for transfer shall not
        constitute the written notice of withdrawal described in Clause 9.
      </li>
    </ol>

    <h2>Intellectual Property</h2>
    <ol start={13}>
      <li>
        The Student and the Parent are prohibited from copying, reproducing,
        modifying, distributing or transmitting in any form or by any means
        whatsoever (including mechanical, electronic, recording, photocopying or
        otherwise) any of Mind Stretcher's curriculum material (be they
        worksheets, notes, study materials or other material in whatever form or
        medium) (<span className="bold">"Prohibited Act"</span>). The Student or
        Parent shall also not instigate, encourage or instruct anyone to carry
        out the Prohibited Act.{" "}
        <span className="bold">
          No part of MS curriculum materials can be reproduced in any form (for
          trade or otherwise) whatsoever; if carried out, this act would
          constitute an infringement of copyrights, for which we may take legal
          action.
        </span>
      </li>
      <li>
        Mind Stretcher may copy, reproduce, display, distribute and use any work
        / material produced by the Student as part of the Registered Course(s).
      </li>
    </ol>

    <h2>Other Conditions</h2>
    <ol start={15}>
      <li>
        For the general benefit of a child, the Parent hereby warrants that the
        Student is registered for the Registered Course(s) at the same level at
        which the Student is currently enrolled for in his / her formal
        education [e.g. if the Student is in P2 at a MOE government primary
        school, the Student must register for P2 course(s) at Mind Stretcher].
      </li>
      <li>
        We reserve the right and the sole discretion to, (a) transfer, combine
        or dissolve a class and change teachers, and/or (b) terminate the
        Student's place in any of the Registered Course(s).
      </li>
      <li>
        While Mind Stretcher will take reasonable precautions to ensure the
        safety of the Student, Mind Stretcher assumes no liability for personal
        injury, loss or damage to property or any loss or damage of any kind
        whatsoever which might be sustained by the Student in undertaking any
        activity or anything during the Registered Course(s), whether under the
        direction of Mind Stretcher or otherwise, and generally for any such
        injury, loss or damage however caused except by the wilful default of
        Mind Stretcher, its servants or agents.
      </li>
      <li>
        The Parent hereby releases Mind Stretcher, its servants and agents from
        all liability for such injury, loss or damage of any other kind
        whatsoever which might be sustained by the Student in undertaking any
        activity or anything during the Registered Course(s) or / and in the
        Centre, whether under the direction of Mind Stretcher or otherwise, and
        generally for any such injury, loss or damage howsoever caused except by
        the wilful default of Mind Stretcher, its servants and agents.
      </li>
      <li>
        Mind Stretcher shall not be liable for any default or closure of classes
        due to any act of God, war, strike, lockout, industrial action, fire,
        flood, drought, tempest or other event beyond Mind Stretcher's
        reasonable control.
      </li>
      <li>
        It is the Parent's responsibility to monitor the health of their
        children before they come to the MS centre for lessons. If the Student
        is not feeling well, we reserve the right to put the Student in the
        office/administration area of the centre or send him home. The fees in
        respect of the lesson for that day will not be refunded. Attendance in
        any make-up classes is subject to clauses 6-7.
      </li>
      <li>
        The Course Registration Form and these General Terms contain the whole
        agreement between the Parent, Student and Mind Stretcher and supersedes
        any prior written or oral agreement amongst them relating to the
        Student's enrolment in the Registered Course(s) and the parties confirm
        that they have not entered into this agreement on the basis of any
        representations that are not expressly incorporated in the Course
        Registration Form and these General Terms.
      </li>
      <li>
        The Parent hereby also consents to the collection, use and disclosure of
        his / her personal data as well as the personal data of the Student in
        accordance with the purposes specified in the{" "}
        <a href="https://www.mindstretcher.com/data-protection-policy">
          MS Personal Data Protection Policy
        </a>
        .
      </li>
    </ol>
  </div>
);
