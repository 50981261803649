import { Box, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayout({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <VStack alignItems="flex-start" gap={2}>
      <Text fontSize="20px" fontWeight={500} lineHeight="100%" color="#013768">
        {subtitle}
      </Text>
      <Text fontSize="28px" fontWeight={700} lineHeight="100%">
        {title}
      </Text>
    </VStack>
  );
}

export default PagedFlowLayout;
