import React from "react";

import "./MSDatePicker.css";
import { InputGroup } from "@chakra-ui/react";

import DatePicker, { DatePickerProps } from "react-datepicker";

export function MSDatePicker(props: DatePickerProps) {
  return (
    // Wrapping in input group so that PhoneInput has access to Chakra variables
    <DatePicker dateFormat="dd MMM yyyy" {...props} />
  );
}
