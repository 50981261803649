// Copied from /shared folder
export const schools = [
  "Global Kids",
  "My Kidz Cottage",
  "Palmview Primary",
  "Kiddie Winkie",
  "Learning Jungle Singapore",
  "Singapore Korean International School",
  "Little Footprints Preschool (West Coast)",
  "British Council",
  "Canberra Preschool Pte Ltd",
  "Sengkang Secondary School",
  "Little Paddington Pre-School",
  "Monfort Junior Primary School",
  "Faith Methodist",
  "Raffles Girl's School",
  "Our First Steps Preschool",
  "Awwa",
  "St Francis Methodist School",
  "Mind Champ",
  "Jurong Calvary Kindergarten",
  "Catholic Junior College",
  "Pei Tong Pri School",
  "German European School Singapore",
  "Valour Primary School",
  "Junior Champs Pre - School",
  "Junior Champs Pre - School",
  "MOE Kindergarten @ Waterway",
  "Anchor Green MOE Kindergarden",
  "Moe Kindergarden Fernvale",
  "MOE Kindergarten @ North Vista",
  "MOE Kindergarten @ Punggol Cove Primary",
  "Carpe Diem Kinder Ace",
  "CHIJ St. Nicholas Girls' School",
  "Pat's Schoolhouse Katong",
  "School4Kids",
  "Whitesands Primary School",
  "Greenland Childcare",
  "CHIJ Our Lady Queen of Peace",
  "Nanyang Junior College",
  "Dimension International",
  "My Preschool (Child Care)",
  "MOE Kindergarten @ Horizon Primary",
  "Middleton International School",
  "MOE Kindergarten @ First Toa Payoh",
  "My First Skool @ Punggol",
  "Childcare @ The Enrichment Ltd.",
  "Canossa Catholic Primary School",
  "Little Skool House @ OCBC",
  "Little Petals Preschool",
  "PCS",
  "ACS (Barker Road)",
  "Brainy Junior Gym",
  "My World Pre-School",
  "Little Sage International Preschool",
  "MOE Kindergarten @ Anchor Green",
  "Greenland Kindergarten",
  "Northshore Primary School",
  "Little Sheperd's Schoolhouse @ Church of Nativity",
  "MOE Kindergarten @ Pei Tong Pri",
  "MOE@Huamin",
  "Singapore Hokkien Huay Kuan",
  "MOE Kindergarten",
  "MOE Kindergarten - Punggol View",
  "PPIS Preschool",
  "Presbyterian High School",
  "MOE Kindergarten @ Valour Primary School",
  "My World Pre-School (Sembawang)",
  "Al-Iman Mosque Kindergarten",
  "Canossa Catholic Primary School",
  "Punggol Green Primary School",
  "Invictus International School",
  "MOE @ North Vista",
  "Kindle Kids International School",
  "Barclyne College International",
  "Dimension International School",
  "GALILER KINDERGARDEN",
  "Jurongville Secondary School",
  "My First Skool @ Braddell Heights",
  "Canossaville Preschool",
  "Little Footprints",
  "New Town Secondary",
  "The Orange Academy",
  "Sengkang Green Primary School",
  "MOE Kindergarten @ Naval Base",
  "Row & Glow @ Pasir Ris",
  "XCL World Academy",
  "XCL World Academy",
  "Anglican High Secondary School",
  "Zhenghua Kindergarden",
  "Dimensions International Primary School",
  "One World International School",
  "Little Kinder Montessori",
  "Crest Secondary School",
  "Sparkletots @ 341 Ubi",
  "MOE Kindergarten @ Greendale",
  "Kaplan Higher Education Academy Singapore",
  "North London Collegiate School",
  "Keiming Primary School",
  "Uwing International School",
  "PCF @ Sparkletots - Potong Pasir",
  "Bukit Panjang Goverment High School",
  "Mee Toh School",
  "SJI Junior",
  "MOE Kindergarten @ Punggol Cove Primary School",
  "Advanced Montessori International",
  "Superland Pre-School Ganges",
  "Moe Kindergarden @ Frontier",
  "My First School @Jurong Point",
  "My Little Kingdom",
  "My First School",
  "My First School",
  "PCF @ Sparkletots (Fajar)",
  "PCF Sparkletots @ Cashew",
  "PCF SPARKLETOTS @ MARSILING BLK 334",
  "PCF Sparkletots @ Zhenghua - Blk 620 Bukit Panjang",
  "PCF SPARKLETOTS @ TECK WHYE Block 19",
  "PCF @ Bukit Timah",
  "PCF @ Toh Yi Drive",
  "My First School @ Blk 375 Clementi",
  "PCF @ Ghim Moh",
  "PCF @ Sparkletots (Punggol Block 101B)",
  "PCF @ Sparkletots (Punggol Walk Blk 271A)",
  "Raffles Kidz@ Punggol",
  "PCF Sparkletots @Clementi blk 330",
  "PCFSparkleTots@Blk 665 Jurong West",
  "Kinderland Preschool @ Novena",
  "PCF @ Sparkletots (Buangkok Green Blk 987C)",
  "PCF @ Sparkletots (Punggol Cove Blk 676B))",
  "My World @ Waterway Sundew",
  "Little Green House(Woodlands)",
  "E-Bridge PreSchool @ Edgedale Plains",
  "My First Skool @ 153 Rivervale Crescent",
  "My First Skool @ 164 Rivervale Crescent",
  "My First Skool @ Hougang 5",
  "St Margaret's Pri School",
  "Ar-raudhah Kindergarten",
  "Little Oxford Schoolhouse",
  "Superland Pre-school (Woodlands)",
  "First Bridge Montessori",
  "Sunflower Preschool (West Rock)",
  "Temasek Junior College",
  "Crescent Girls' School",
  "BCS International College",
  "Brighton College SG",
  "AspenKidz Preschool",
  "PCF Sparkletots @ 326 Punggol Coast",
  "ROSYTH Child Care Centre",
  "Chan's Montessori Pre-school",
  "Maris Stella High School",
  "Haven Schoolhouse",
  "KidsCampus West Coast",
  "Merchiston International School",
  "Pei Ying Primary School",
  "MOE Kindergarten @ Fernvale",
  "Agape Little Uni Clementi",
  "Small Wander",
  "Cherry Kidz",
  "Sprindale kindergarten",
  "Zhong Hua Secondary School",
  "North London Collegiate School",
  "MK Kindergarden@North Vista Primary School",
  "Little Seeds Preschool",
  "MOE Kindergarten @ Fern Green",
  "Singapore Chinese Girls School",
  "ACS (Primary)",
  "Sharda Kindergarten",
  "skoo14kidz@Tampines Greenforest",
  "MK@ Gongshang",
  "Primus Schoolhouse Alexandra",
  "SDM Childcare",
  "Myanmar International Academy",
  "The Class Of One - India International School (Online)",
  "Hebron Christian Preschool",
  "Cambridge Preschool",
  "Yuvabarathi International School",
  "Canadian International School",
  "Skool4Kidz",
  "Dunearn Secondary School",
  "St. Andrew's Mission School",
  "Millennia Institute",
  "MOE Kindergarden @ Dazhong Primary School",
  "Mayflower Primary School",
  "Singapore International School (Hong Kong)",
  "E-Bridge Pre-School @ Bukit Batok West",
  "NurtureStars Preschool",
  "Wisdom Star Preschool",
  "Lily Valley Preschool",
  "Little Wonder",
  "Sanyu Adventist School",
  "Hanbridge Institute",
  "My Little Campus (Canberra)",
  "My Little Campus (Pasir Ris) Pte. Ltd.",
  "My Little Campus (Sembawang)",
  "My Little Campus (Wellington) Pte. Ltd.",
  "3 House Kindergarten",
  "A & J Child Care Centre",
  "Abc Children'S Place",
  "Ableland @ Tampines Pte. Ltd.",
  "Academy Kidz Inc Pte. Ltd.",
  "Academy Of Image Mastery",
  "ACE @ Work Childcare",
  "ACE @ Work Childcare (Cashew Road)",
  "ACE @ Work Childcare (Bukit Batok)",
  "ACE @ Work Educare Pte Ltd",
  "ACE @ Work Educare Service",
  "ACE @ Work Enrichment Pte Ltd",
  "ACE Child care",
  "ACE House",
  "ACE Montessori House Kindergarten",
  "ACE@Work Childcare Pte Ltd",
  "Aceedu@singapore Pte Ltd",
  "Acekidz @ Community",
  "Acekidz Creative Educare",
  "Aces Montessori Kidz Child Care Pte. Ltd.",
  "Aces Montessori Kidz Kindergarten",
  "Acorn Childcare Centre",
  "Acorn Kindergarten",
  "Acorn Premium",
  "Active Learners (Preschool , Childcare & Kindergarten)",
  "Adelfibel Infant And Child Development Centre Pte Ltd",
  "Admiral Hill Preschool Pte Ltd",
  "Admiralty Primary School",
  "Admiralty Secondary School",
  "Ado Playhouse",
  "Adroit Asset Management Pte. Ltd.",
  "Adventureland Kidz Llp",
  "AEC College",
  "Agape Child Care(Cck) Pte. Ltd.",
  "Agape Child Care(Jw) Pte. Ltd.",
  "Agape Child Care(Sk) Pte Ltd",
  "Agape Kids Campus Llp",
  "Agape Little Uni (Kallang) Pte. Ltd.",
  "Agape Little Uni @ Central Pte. Ltd.",
  "Agape Little Uni @ Serangoon Pte. Ltd.",
  "Agape Little Uni @Orange Grove (Pte.) Ltd.",
  "Agape Little Uni. @ Cecil Pte. Ltd.",
  "Agape Little Uni. @ Clementi Pte. Ltd.",
  "Agape Little Uni. Pte. Ltd.",
  "Agape Montessori House Kindergarten",
  "Ahmad Ibrahim Primary School",
  "Ahmad Ibrahim Secondary School",
  "Ai Tong School (Primary)",
  "Al - Amin Education Centre Pte Ltd",
  "Al - Amin Pre-School",
  "Al - Amin Preschool (Pasir Ris)",
  "Al - Ansar Mosque Kindergarten",
  "Al - Iman Mosque Kindergarten",
  "Al - Istighfar Mosque Kindergarten",
  "Al - Istiqamah Mosque Kindergarten",
  "Al - Khair Mosque Kindergarten",
  "Al - Mukminin Mosque Kindergarten",
  "Al - Muttaqin Mosque Kindergarten",
  "Al - Nasry @ Jurong West Kindergarten",
  "Al - Nasry Kindergarten",
  "Al- Irsyad Kindergarten",
  "Alexandra Primary School",
  "Allcare Child Development Centre",
  "Al-Nasry @ Jurong West Kindergarten",
  "Al-Nasry Kindergarten",
  "Alphabet Playhouse @ East Coast Pte Ltd",
  "Alphabet Playhouse @ Katong Pte. Ltd.",
  "Alphabet Playhouse @ Mountbatten Pte. Ltd.",
  "Alphabet Playhouse Child Care And Learning Centre Pte Ltd",
  "Amar Kidz @ Hougang Pte. Ltd.",
  "Amar Kidz Enterprise Pte. Ltd.",
  "Amazing Star Montessori House",
  "Amazing Star Montessori House (Cck)",
  "Amazing Star Montessori House (Greenridge)",
  "Amazing Star Montessori House (Sk)",
  "Amazing Star Montessori House (Sk2)",
  "Amazing Star Montessori House (Sn)",
  "Amazing Star Montessori House (Ubi)",
  "Americare Child Development Centre",
  "An - Nur Mosque Kindergarten",
  "Anchor Green Primary School",
  "Anderson Primary School",
  "Anderson Secondary School",
  "Ang Mo Kio Primary School",
  "Ang Mo Kio Secondary School",
  "Angel Care Centre Pte Ltd",
  "Anglican High School",
  "Anglo-Chinese School (Barker Road)",
  "Anglo-Chinese School (Independent)",
  "Anglo-Chinese School (International) Singapore (ACS International)",
  "Anglo-Chinese School (Junior)",
  "Anglo-Chinese School (Primary)",
  "An-Nahdhah Mosque Childcare & Pre-School",
  "Apple Tree Kindergarten",
  "Appleland Montessori Child Care Centre",
  "Appleland Montessori Childcare Centre & Pre-School",
  "Appleland Playhouse",
  "Appleland Playhouse Childcare Centre & Pre-School",
  "Apricot Academy (Crescent) Pte Ltd",
  "Apricot Academy (Katong) Pte Ltd",
  "Apricot Academy (Laguna) Pte. Ltd.",
  "Ar - Raudhah Mosque Kindergarten",
  "Arise Kindergarten",
  "Arts Junior Montessori Llp",
  "Artskidz Preschool",
  "Ascenda Learning Hub",
  "Ascension Kindercare",
  "Ascension Kindergarten",
  "Aspiration Child Care Centre",
  "Aspire Hub Education Pte Ltd",
  "Assumption English School",
  "Assyakirin Mosque Kindergarten",
  "Australian International School Singapore (AISS)",
  "Avondale Grammar School Singapore (AGS)",
  "Babies By-The-Park Pte. Ltd.",
  "Babies Inc. Montessori Pte Ltd",
  "Babyplanet Montessori Pte. Ltd.",
  "Balestier Hill Primary School",
  "Balestier Hill Secondary School",
  "Bambini Childcare LLP",
  "Bambini Montessori Pte. Ltd.",
  "Barker Road Methodist Church Kindergarten",
  "Bartley Secondary School",
  "Beacon Primary School",
  "Bearrington Child Care And Development Centre",
  "Beatty Secondary School",
  "Bedok Green Primary School",
  "Bedok Green Secondary School",
  "Bedok Lutheran Church Kindergarten",
  "Bedok Methodist Church Kindergarten",
  "Bedok North Secondary School",
  "Bedok South Secondary School",
  "Bedok Town Secondary School",
  "Bedok View Secondary School",
  "Bedok West Primary School",
  "Bendemeer Primary School",
  "Bendemeer Secondary School",
  "Berriis Kids Campus",
  "Berrise Kids Child Care",
  "Best World Learning Centre Pte Ltd",
  "Bestcare Montessori Pte Ltd",
  "Bethany Child Care Centre",
  "Bethany Kindergarten",
  "Bethany Presbyterian Child Development Centre",
  "Bethel Child Development Centre",
  "Bethel Day Care Centre",
  "Bethel Kindergarten",
  "Bethesda (AMK) Kindergarten",
  "Bethesda (Bedok-Tampines) Kindergarten",
  "Bethesda (Bukit Arang) Kindergarten",
  "Bethesda (Depot Walk) Kindergarten",
  "Bethesda (Katong) Kindergarten",
  "Bethesda (Pasir Ris) Kindergarten",
  "Bethesda (Serangoon) Sunbeam Kindergarten",
  "Bethesda Chapel Kindergarten",
  "Bethesda Chapel Ltd",
  "Bethesda Frankel Kindergarten",
  "Bethlehem Educare Centre",
  "Bethlehem Educare Centre (Yishun)",
  "Between Two Trees Preschool",
  "Bibinogs Preschool",
  "Bibinogs Preschool @ Kings Arcade",
  "Bible Center Kindergarten",
  "Big Apple Child Care And Development Centre",
  "Big Man Toys",
  "Bingo Preschool",
  "Bishan Park Secondary School",
  "Bishan-Toa Payoh North Ccc Child Care & Development Centre",
  "Bishan-Toa Payoh North Kindergarten",
  "Biz Kidz Pte Ltd",
  "Blangah Rise Primary School",
  "Bleu Castle Llp",
  "Bloom Educare Pte Ltd",
  "Blossom Aspiration Centre Llp",
  "Blossom Berrykidz Centre Llp",
  "Blossom Creative Centre Llp",
  "Blossom Discovery Centre Llp",
  "Blossom Educare Centre Llp",
  "Blubberland",
  "Boon Lay Garden Primary School",
  "Boon Lay Secondary School",
  "Bowen Secondary School",
  "Brain Buttons Kindergarten",
  "Brainworks Concept. Pte. Ltd.",
  "Brainy Child Montessori Learning Centre LLP",
  "Brainy Kids Schoolhouse",
  "Brainy Kids Schoolhouse Pte. Ltd.",
  "Bridges Montessori Kindergarten",
  "Bridges Montessori Pre-School",
  "Bright Child Educare Centre",
  "Bright Juniors @ Pgl Pte. Ltd.",
  "Bright Juniors @ Sc Pte. Ltd.",
  "Bright Juniors @ Tm Pte. Ltd.",
  "Bright Juniors @ Ys Pte. Ltd.",
  "Bright Juniors Pte. Ltd.",
  "Bright Kids @ Jurong West Pte Ltd",
  "Bright Kids @ Pasir Ris Pte Ltd",
  "Bright Kids School House Pte Ltd",
  "Bright Kids @ Punggol",
  "Brighton Montessori",
  "Brighton Montessori International Pre-School @ Fort",
  "Brighton Montessori International Pre-School @ Frankel",
  "Brighton Montessori International Pre-School @ River Valley",
  "Brighton Montessori International Pre-School @ Sunset",
  "Brights Junior Childcare & Pre-School",
  "Brightsun Child Care Centre",
  "Brilliant Tots Childcare",
  "Brilliant Tots Childcare (Bukit Batok)",
  "British Council Nursery School",
  "Broadrick Secondary School",
  "Browse Locations Kallang / Whampoa",
  "Bubblesland Playhouse Pte Ltd",
  "Bukit Arang Kingdergarten",
  "Bukit Batok Secondary School",
  "Bukit Merah Secondary School",
  "Bukit Panjang Govt. High School",
  "Bukit Panjang Methodist Church Kindergarten",
  "Bukit Panjang Primary School",
  "Bukit Timah Primary School",
  "Bukit View Primary School",
  "Bukit View Secondary School",
  "Bumble Bee Child Care Centre",
  "Bumble Bee Childcare Centre",
  "Buttercup Montessori Kindergarten",
  "Buttercups Kindergarten",
  "Buttercups Kindergarten (Pasir Panjang)",
  "Buttercups Kindergarten (Rochester)",
  "Buttercups Montessori Kindergarten",
  "Caelum Junior Llp",
  "Cairnhill Educare Pte Ltd",
  "Calvary Baptist Kindergarten / Preschool",
  "Calvary Pandan Kindergarten",
  "Camberley International Pre-School Private Limited",
  "Cambridge Cdc @ Bt Pte. Ltd.",
  "Cambridge Cdc @ Sengkang Pte. Ltd.",
  "Cambridge Cdc @ Tanglin Pte. Ltd.",
  "Cambridge Childcare & Kindergarden",
  "Cambridge Development Learning Centre",
  "Canaan Church (SCCC) Kindergarten",
  "Canadian International School (Singapore)",
  "Canberra Primary School",
  "Canberra Secondary School",
  "Candy Kids Child Care",
  "Candyland Infant & Childcare Centre Llp",
  "Canopy Schoolhouse Pte. Ltd.",
  "Canossa Convent Primary School",
  "Canossian Convent Kindergarten",
  "Cantonment Primary School",
  "Care Corner Child Development Centre",
  "Care Corner Child Development Centre (Admiralty)",
  "Care Corner Child Development Centre (Canberra Road)",
  "Care Corner Child Development Centre (Leng Kee)",
  "Care Corner Child Development Centre (St George)",
  "Carpe Diem @ Hilltop Pte. Ltd.",
  "Carpe Diem @ ITE Pte. Ltd.",
  "Carpe Diem Childcare Pte. Ltd.",
  "Carpe Diem Childcare Resort Pte. Ltd.",
  "Carpe Diem Childcare Resort Pte. Ltd. (Punngol)",
  "Carpe Diem Creative Childcare Pte. Ltd.",
  "Carpe Diem Educare Pte. Ltd.",
  "Carpe Diem First Years Pte. Ltd.",
  "Carpe Diem Juniors Pte. Ltd.",
  "Carpe Diem Kids Academy",
  "Carpe Diem Kids Academy @ Watten Pte. Ltd.",
  "Carpe Diem Kidsworld Pte. Ltd.",
  "Carpe Diem Kidz Playhouse Pte. Ltd.",
  "Carpe Diem Kidz Pte. Ltd.",
  "Carpe Diem Kidz Universe Pte. Ltd.",
  "Carpe Diem Kindercare Pte. Ltd.",
  "Carpe Diem Little Green House Pte. Ltd.",
  "Carpe Diem Mi Preschool Pte. Ltd.",
  "Carpe Diem Mi Schoolhouse Pte. Ltd.",
  "Carpe Diem Playskool Pte. Ltd.",
  "Carpe Diem Pre-school Pte Ltd",
  "Carpe Diem Preschool Pte. Ltd.",
  "Carpe Diem Schoolhouse Pte. Ltd.",
  "Carpe Diem Springs Pte. Ltd.",
  "Carpe Diem Starlets 1 Pte. Ltd.",
  "Carpe Diem Starlets 2 Pte. Ltd.",
  "Carpe Diem Starlets Pte. Ltd.",
  "Carpe Diem Young Hearts Pte. Ltd.",
  "Carpe Diem Young Joy Pte. Ltd.",
  "Carpe Diem Young Joy Pte. Ltd. (Fajar Road)",
  "Carpe Diem Young Minds Pte Ltd",
  "Carpe Diem Young Minds Pte. Ltd. (Tampines)",
  "Casuarina Primary School",
  "Catholic High School (Primary)",
  "Catholic High School (Secondary)",
  "Catholic Kindergarten",
  "Cavenur Kindergarten",
  "CCM Childcare",
  "Cedar Girls' Secondary School",
  "Cedar Primary School",
  "Centre of New Life Kindergarten",
  "CGM Kindergarten",
  "Champkids' Academy Pte. Ltd.",
  "Champz School House Pte. Ltd.",
  "Changi Baptist Church Kindergarten",
  "Changi Bethany Kindergarten",
  "Changi Bethany School-House",
  "Changi General Hospital Child Care Centre",
  "Changkat Changi Secondary School",
  "Changkat Primary School",
  "Chao Yang School",
  "Chappie Tots Center Pte Ltd",
  "Character Montessori Asia Pte Ltd",
  "Character Montessori Pasir Ris Pte. Ltd.",
  "Character Montessori Punggol (Pte. Ltd.)",
  "Charis Montessori Preschool",
  "Chatsworth International School",
  "Chatsworth Kindergarten",
  "Chatsworth Kindergarten (Bedok)",
  "Chee Hoon Kog Child Care Centre",
  "Chee Hwan Kog Childcare Centre",
  "Cheery Infant And Child Care Pte Ltd",
  "Cheeryland School House Pte. Ltd.",
  "Chen Li Kindergarten",
  "Chen Su Lan Methodist Children'S Home (Child Care Division)",
  "Cherie Hearts (Infant Care / Childcare / Pre-School)",
  "Cherie Hearts @ Admirax Pte. Ltd.",
  "Cherie Hearts @ Bedok Central Pte. Ltd.",
  "Cherie Hearts @ Bedok Reservoir Pte. Ltd.",
  "Cherie Hearts @ Bukit Purmei Pte. Ltd.",
  "Cherie Hearts @ Changi Business Park Pte. Ltd.",
  "Cherie Hearts @ Charlton Pte Ltd",
  "Cherie Hearts @ Depot Pte. Ltd.",
  "Cherie Hearts @ Eastgate Pte Ltd",
  "Cherie Hearts @ Eng Kong Pte. Ltd.",
  "Cherie Hearts @ Gombak Pte. Ltd.",
  "Cherie Hearts @ International Business Park Pte. Ltd.",
  "Cherie Hearts @ Kk Pte. Ltd.",
  "Cherie Hearts @ Mountbatten Pte. Ltd.",
  "Cherie Hearts @ Namly Pte. Ltd.",
  "Cherie Hearts @ Northstar Pte. Ltd.",
  "Cherie Hearts @ Purvis Pte. Ltd.",
  "Cherie Hearts @ Science Park I Pte. Ltd.",
  "Cherie Hearts @ Sk Pte. Ltd.",
  "Cherie Hearts @ South Buona Vista (Pte.) Ltd.",
  "Cherie Hearts @ The Chevrons Pte Ltd",
  "Cherie Hearts @ Vertex Pte. Ltd.",
  "Cherie Hearts Bright Kidz Pte. Ltd.",
  "Cherie Hearts Corporate Pte. Ltd.",
  "Cherie Hearts Discoveryland Pte. Ltd.",
  "Cherie Hearts Elite Tots Pte. Ltd.",
  "Cherie Hearts Funhouse Pte Ltd",
  "Cherie Hearts Funhouse Pte. Ltd.",
  "Cherie Hearts Justkidz Pte. Ltd.",
  "Cherie Hearts Kids 'r' Us Preschool Pte Ltd",
  "Cherie Hearts Kids-At-Play Pte. Ltd.",
  "Cherie Hearts Kidz Campus Pte. Ltd.",
  "Cherie Hearts Kidz Cove Pte. Ltd.",
  "Cherie Hearts Kidz Kingdom Pte. Ltd.",
  "Cherie Hearts Kidz Lodge Pte. Ltd.",
  "Cherie Hearts Little Angels Pte. Ltd.",
  "Cherie Hearts Little Blossoms Pte. Ltd.",
  "Cherie Hearts Little Play House Pte Ltd",
  "Cherie Hearts Little Tots Cottage Pte. Ltd.",
  "Cherie Hearts Natureland Pte Ltd",
  "Cherie Hearts Nurture Cottage Pte. Ltd.",
  "Cherie Hearts Nurture Cottage",
  "Cherie Hearts Nurtureloft Pte. Ltd.",
  "Cherie Hearts Nurturing Land Pte. Ltd.",
  "Cherie Hearts Preschool & Early Development Centre Pte. Ltd.",
  "Cherie Hearts Preschool Pte. Ltd.",
  "Cherie Hearts Preschool Team Pte. Ltd.",
  "Cherie Hearts Treehouse Pte. Ltd.",
  "Cherie Hearts@Changi South Pte. Ltd.",
  "Cherrybrook Kindergarten",
  "Chestnut Drive Secondary School",
  "Chicc Profiling LLP",
  "CHIJ (Katong) Primary",
  "CHIJ (Kellock)",
  "CHIJ Katong Convent",
  "CHIJ Our Lady Of Good Counsel",
  "CHIJ Our Lady Of The Nativity",
  "CHIJ Our Lady Queen Of Peace",
  "CHIJ Primary (Toa Payoh)",
  "CHIJ Secondary (Toa Payoh)",
  "CHIJ St. Joseph'S Convent",
  "CHIJ St. Nicholas Girls' School (Primary)",
  "CHIJ St. Theresa'S Convent",
  "Child At Street 11 Ltd",
  "Childfirst",
  "ChildFirst Preschool",
  "Childfirst@Dunearn Pte. Ltd.",
  "Childland Pte Ltd",
  "Children'S Vineyard Kindergarten",
  "Children'S World Montessori Child Care Pte. Ltd.",
  "Chiltern House",
  "Chiltern House Pre-School",
  "Chiltern House East Coast",
  "Chiltern House Forum",
  "Chiltern House Mountbatten",
  "Chiltern House Turf Club",
  "Chinese Christian Mission Limited",
  "Chinese Christian Mission School",
  "Chinese International School Singapore",
  "Chinese Kindergarten",
  "Choa Chu Kang Bible Centre Kindergarten",
  "Chong Boon Secondary School",
  "Chongfu School (Primary)",
  "Chongzheng Primary School",
  "Chow & Chows Childcare & Early Learning Centre Ltd.",
  "Christ Church Kindergarten",
  "Christ Church Secondary School",
  "Chrysalis Child Care Centre",
  "Chua Chu Kang Primary School",
  "Chua Chu Kang Secondary School",
  "Chummy Child Care Centre",
  "Chung Cheng High School (Main)",
  "Chung Cheng High School (Yishun)",
  "Church Of Holy Trinity Kindergarten",
  "Ci Ai Education Incubator",
  "Ci You Child Care Centre",
  "Ci You Infant & Toddler Care Centre",
  "Citikids Academy Private Limited",
  "Citikids Academy Pte Ltd",
  "Citikids Campus Pte. Ltd.",
  "Citikids Edventure Pte. Ltd.",
  "Citikids Group",
  "Clavary Pandan Kindergarten",
  "Clementi Primary School",
  "Clementi Town Secondary School",
  "Clementi Woods Kindergarten",
  "Clementi Woods Secondary School",
  "Club Hdb Child Care Centre",
  "CM Central Pte Ltd",
  "Columbia Academy",
  "Columbia Academy Pte Ltd",
  "Columbia Junior Academy Pte. Ltd.",
  "Commonwealth Secondary School",
  "Compassvale Primary School",
  "Compassvale Secondary School",
  "Concord Primary School",
  "Contium Pte Ltd",
  "Coral Primary School",
  "Coral Secondary School",
  "Corporation Primary School",
  "Creative Inventors Preschool",
  "Creative Inventors Pte Ltd",
  "Creative Land Childcare And Development Centre",
  "Creative O Preschoolers' Bay",
  "Creative Star Kids Campus",
  "Creative Star Kidscampus",
  "Creative Thinkers Kindergarten",
  "Creative Thinkers Kindergarten & Learnning Centre",
  "Creative Twinkles Preschool",
  "Creativeland Childcare And Development Centre @Admiralty",
  "Creativeland Childcare And Development Centre(Yew Tee)",
  "Creativeland Childcare & Development Centre",
  "Crescent Girls' School",
  "Crystal Tots Child Development Centre",
  "CS Montessori Kindergarten @ Serangoon",
  "Cuddle Kids Playhouse Pte. Ltd.",
  "Curious Minds Childcare Pte. Ltd.",
  "Da Qiao Primary School",
  "Damai Primary School",
  "Damai Secondary School",
  "Damien Centre Kindergarten",
  "Dardis Child Development Centre Pte.Ltd.",
  "Darul Ghufran Mosque Kindergarten",
  "Darul Makmur Mosque Kindergarten",
  "Darussalam Mosque Kindergarten",
  "Dayspring Kindergarten",
  "Dayspring Kindergarten @ Marina Country Club",
  "Daystar Child Development Centre",
  "Dazhong Primary School",
  "De La Salle School (Primary)",
  "Deerland Pre - School By Bishan North Ccc",
  "Del-Care Edu Centre Pte Ltd",
  "DelCare- Childhood Education And Childcare Services",
  "Derbyshire Infant Care",
  "Derbyshire Kindergarten",
  "Dexkids Schoolhouse",
  "Deyi Secondary School",
  "Discovery Garden (Pte.) Limited",
  "Discovery Garden Pte Ltd",
  "Discovery Kidz Preschool Pte Ltd",
  "Discovery Planet Limited Partnership",
  "D'Joy Children'S Centre",
  "Dnc (Education) Pte. Ltd.",
  "Dover Court Preparatory School",
  "DPS International School Singapore",
  "Dulwich College (Singapore)",
  "Dunearn Secondary School",
  "Dunman High School",
  "Dunman Secondary School",
  "Dynamic World Montessori Preschool",
  "Eager Beaver School House",
  "Eager Beaver Schoolhouse 1 Pte. Ltd.",
  "Eager Beaver Schoolhouse 2 Pte. Ltd.",
  "Eager Beaver Schoolhouse 3 Pte. Ltd.",
  "Eagle Retirement Planners Pte Ltd",
  "Early Learning Centre Pte Ltd",
  "Early Years Montessori Kindergarten",
  "East Coast Primary School",
  "East Spring Primary School",
  "East Spring Secondary School",
  "East View Primary School",
  "East View Secondary School",
  "Ecokidd By Eduventure Pte Ltd",
  "Ecokidd Childcare Private Limited",
  "Ecokidd Kindergarten / Childcare centre",
  "Ecokidd Kindergarten @ Jurong",
  "Ecokidd Kindergarten @ Toa Payoh",
  "Edelweiss 21 Child Care Services",
  "Edelweiss Kindergarten Sembawang Presbyterian Church",
  "Edgefield Primary School",
  "Edgefield Secondary School",
  "Educa Preschool Pte Ltd",
  "Educa Pte Ltd",
  "Educa-Zion Group Pte. Ltd.",
  "Educhamps",
  "Educhamps Childcare (Private) Limited",
  "Eduventure Childcare Centre Pte Ltd",
  "Eileen'S Childcare Centre Pte Ltd",
  "Eileen'S Learning Centre Pte. Ltd.",
  "Eis International Pre-school Pte Ltd",
  "Elfa Children'S Place",
  "ELFA Infant Care & Childcare",
  "Elias (Woodlands) Kindergarten",
  "Elias Kindergarten",
  "Elias Park Primary School",
  "Eliasia Kindergarten",
  "Elim Kindergarten",
  "Elite Learning Kindergarten",
  "Elite Learning Kindergarten (Woodlands)",
  "Elite Transport Services",
  "Emmanuel Child Educare",
  "Endeavour Primary School",
  "Enfant Educare Pte Ltd",
  "En-Naeem Mosque Kindergarten",
  "Eq Educare Pte Ltd",
  "Eq-learners Educare Pte Ltd",
  "Erp Childcare Centre",
  "Eshkol Valley @ Sengkang Pearl Pte. Ltd.",
  "Eton House International Education Group",
  "Eton House International Primary School and Preschool",
  "Etonhouse International (Pre School)",
  "EtonHouse International Research Preschool",
  "Etonhouse International School Pte Ltd",
  "Etonhouse Pre-school Pte Ltd",
  "Eunos Primary School",
  "Eureka Schoolhouse Pte Ltd",
  "Evangel Kindergarten",
  "Evangel Kindergarten / Childcare",
  "Evergreen Primary School",
  "Evergreen Secondary School",
  "Everland Child Care & Development Centre",
  "Everland Childcare",
  "Excel League Pte Ltd",
  "Fairfield Methodist Church Kindergarten",
  "Fairfield Methodist School (Primary)",
  "Fairfield Methodist School (Secondary)",
  "Faith Educare Centre / Childcare / Pre-school",
  "Faith Educare Centre",
  "Faith Educare Centre (Sengkang)",
  "Faith Kindergarten",
  "Faith Montessori",
  "Fajar Secondary School",
  "Far Eastern Kindergarten",
  "Farrer Park Primary School",
  "Fengshan Primary School",
  "Fernvale Primary School",
  "Fey Siang Kindergarten",
  "First Junior Preschool",
  "First Step Child Care And Child Development Centre",
  "First Step Montessori House",
  "First Toa Payoh Primary School",
  "First Toa Payoh Secondary School",
  "Flamingo Child Care Centre Pte. Ltd.",
  "Flamingo Childcare Centre",
  "Foochow Methodist Church Kindergarten",
  "French School of Singapore",
  "Frontier Primary School",
  "Fuchun Primary School",
  "Fuchun Secondary School",
  "Fuhua Primary School",
  "Fuhua Secondary School",
  "Funland Educare Centre",
  "Funtatic School House",
  "Galilee Kindergarten",
  "Gallery Kindergarten",
  "Gan Eng Seng Primary School",
  "Gan Eng Seng School (Sec)",
  "Generation Kidz @ Limau Pte. Ltd.",
  "Genesis Child Care Centre",
  "Genevieve's Pre-school Pte Ltd",
  "Genius Hive Pre-School Pte. Ltd.",
  "Genius School House",
  "George Washington Preschool",
  "George Washington Preschool, Singapore (GWP)",
  "German European School Singapore[10]",
  "Geylang Chinese Methodist Kindergarten",
  "Geylang Methodist School (Primary)",
  "Geylang Methodist School (Secondary)",
  "Giucklich Schoolhouse",
  "Giving Tre3",
  "Global Child Development Centre",
  "Global Indian Foundation Ltd",
  "Global Indian International School Singapore (GIIS)",
  "Global Junior House",
  "Global Tots @ Holland LLP",
  "Globalkids Pte Ltd",
  "Globalkids@Serangoon Pte. Ltd.",
  "Glorious Childcare Je 316 Pte. Ltd.",
  "Glory Joy Child Development Centre",
  "Glory Kindergarten",
  "Gloryland Learning Centre",
  "Gloryland Learning Centre At Toh Tuck Pte Ltd",
  "Gloryland Learning Centre Pte. Ltd.",
  "Glucklich Schoolhouse",
  "Gongshang Primary School",
  "Good Shepherd Convent Kindergarten",
  "Grace Child Development Centre",
  "Grace House Kindergarten",
  "Gracefields Childcare",
  "Gracefields Kindergarten",
  "Great Beginnings Montessori Pre-school",
  "Green Pastures Child Centre",
  "Greendale Primary School",
  "Greendale Secondary School",
  "Greenery Childcare Centre",
  "Greenfield Montessori Child Care Pte. Ltd.",
  "Greenridge Primary School",
  "Greenridge Secondary School",
  "Greentree Montessori Children Hub Pte. Ltd.",
  "Greentree Montessori Kindergarten",
  "Greentree School Of Education Pte Ltd",
  "Greenview Secondary School",
  "Greenwood Primary School",
  "Griffiths Primary School",
  "Growing Up Gifted",
  "Guangyang Primary School",
  "Guangyang Secondary School",
  "GUG The Kindergarten",
  "GUG The Kindergarten (Mountbatten)",
  "Guillemard Crescent",
  "Hai Sing Catholic School",
  "Haig Girls' School",
  "Halifax Montessori Childcare",
  "Halifax Montessori Childcare Pte Ltd",
  "Hampton Pre School / Childcare",
  "Hampton Pre-School",
  "Hanis Montessori Kindergarten",
  "Hanis Montessori Kindergarten (Choa Chu Kang)",
  "Hanis Montessori Kindergarten (Enterprise One)",
  "Hanis Montessori Kindergarten (Kovan)",
  "Hanis Montessori Kindergarten (Pasir Ris)",
  "Hanis Montessori Kindergarten (Tampines)",
  "Hanis Montessori Kindergarten (Woodlands)",
  "Happy Brainy Kidz In Wonderland",
  "Happy Brainy Kidz In Wonderland",
  "Happy Camper @Sengkang Pte. Ltd.",
  "Happy Family @ Kovan Pte. Ltd.",
  "Happy Family Child Care Centre",
  "Happy Family Child Care Centre Pte. Ltd.",
  "Happy Family Edu-Hub Pte. Ltd.",
  "Happy Kids Childcare & Development Centre",
  "Happy Kids Childcare / Development Centre / Learnning Centre",
  "Happy Kids Early Learning Centre",
  "Happy Talent Childcare Centre",
  "Happy Town Child Care Centre",
  "Harmony Child Care & Development Centre",
  "Hasanah Mosque Kindergarten",
  "Healthy Start Child Development Centre",
  "Helios EduCare Pte Ltd",
  "Helios Educare Pte. Ltd.",
  "Henderson Secondary School",
  "Henry Park Primary School",
  "Hess Education Centre",
  "Hillgrove Secondary School",
  "His Biz Pte. Ltd.",
  "His Little Kingdom Child Care And Development Centre",
  "His Little Lighthouse Education Centre",
  "Hollandse School",
  "Holy Family Kindergarten",
  "Holy Grace Kindergarten",
  "Holy Innocents' High School",
  "Holy Innocents' Primary School",
  "Holy Trinity Church Kindergarten",
  "Home School",
  "Honey Tree @ Bedok Pte. Ltd.",
  "Honey Tree @ Siglap Pte Ltd",
  "Honey Tree Schoolhouse Pte Ltd",
  "Hong Kah Primary School",
  "Hong Kah Secondary School",
  "Hong Wen School (Primary)",
  "Hope Community Kindergarten",
  "Hope Community Kindergarten (Choa Chu Kang)",
  "Hope Community Kindergarten (Sembawang)",
  "Hope Community Kindergarten (Tampines)",
  "Horizon Primary School",
  "Hougang Primary School",
  "Hougang Secondary School",
  "House Of Kids @ Butterfly Ave",
  "House Of Kids Pte. Ltd.",
  "House of Kids Childcare & Development Centre",
  "House On The Hill Pte Ltd",
  "Hua Yi Secondary School",
  "Huamin Primary School",
  "Huda Childcare Pte. Ltd.",
  "Hui Ming Child Development Centre",
  "Hwa Chong Institution",
  "Hwa Chong International School (HCIS)",
  "Ichiban (Yunnan) Childcare Centre",
  "Ichiban Montessori Child Care Centre",
  "Ichiban Montessori Child Care Centre (Fernvale)",
  "Ichiban Montessori Child Care Centre Pte Ltd",
  "Ichiban Montessori Kiddie Kollege",
  "Ichiban Montessori Kindergarten",
  "Ichiban Montessori Kindergarten / Child Care Centre",
  "Ichiban Nurtureland Kindergarten",
  "Ichiban Nurtureland Kindergarten (Choa Chu Kang)",
  "Ideal Montessori Pte Ltd",
  "Ikids Preschoolers",
  "Iman Childcare ( Woodgrove) Pte. Ltd.",
  "Iman Childcare (Simei) Pte. Ltd.",
  "Iman Childcare Pte. Ltd.",
  "Iman Kindergarden & Childcare",
  "Iman Kindergarten (Bt. Batok)",
  "Iman Kindergarten (CCK)",
  "Iman Kindergarten (Jurong)",
  "Iman Kindergarten (Tampines)",
  "Iman Kindergarten (Tenagaville)",
  "Iman Kindergarten (Yishun)",
  "Impresario Arts Education",
  "Innate Montessori Pte Ltd",
  "Innate Montessori Pte. Ltd.",
  "Innova Primary School",
  "Insworld Institute",
  "Integrated International School (IIS)",
  "International Community School (Singapore)",
  "International Competencies Academy Pte Ltd",
  "International School Singapore (ISS)",
  "Island Playhouse-Child Development Centre Pte Ltd",
  "Ismart Montessori Kindergarten",
  "Iyad Child Development Centre / Pre School / Kindergarten",
  "Iyad Perdaus Child Development Centre",
  "Iyad-Perdaus Nurture Care",
  "J. Bowen Pte Ltd",
  "Jamiyah Child Care Centre",
  "Jamiyah Kindergarten",
  "Japanese Kindergarten (S) Pte Ltd",
  "Jeet'S School House (Bedok Kindergarten)",
  "Jeet'S School House (Kindergarten)",
  "Jeet'S School Pte. Ltd.",
  "Jenius Kindergarten",
  "Jenius Kindergarten (Hougang)",
  "Jessin Kindergarten",
  "JHS Montessori Kindergarten",
  "Jiemin Primary School",
  "Jing Shan Primary School",
  "Jingles Childcare Centre",
  "Josiah Associates Pte Ltd",
  "Josiah Babies",
  "Josiah Montessori : City Childcare Centre",
  "Josiah Montessori Kinder-Care",
  "Joy Little Schoolhouse Pte. Ltd.",
  "Joy Montessori Schoolhouse",
  "Joy Talent Childcare Centre",
  "Joyland Child Care & Development Centre @ Jurongllp",
  "Jude Child Care And Child Development Centre",
  "Junior Playworld Child Care & Development Centre",
  "Junyuan Primary School",
  "Junyuan Secondary School",
  "Jurong Calvary Kindergarten",
  "Jurong Christian Church Kindergarten",
  "Jurong Primary School",
  "Jurong Secondary School",
  "Jurong West Primary School",
  "Jurong West Secondary School",
  "Jurongville Secondary School",
  "Just Kids @ Choa Chu Kang Pte. Ltd.",
  "Just Kids @ Jurong Pte. Ltd.",
  "Just Kids @ Taman Jurong Pte. Ltd.",
  "Just Kids @ Tampines Pte. Ltd.",
  "Just Kids @ Yishun Pte. Ltd.",
  "Just Kids @Marine Parade Pte. Ltd.",
  "Just Kids Child Care / Learnning Centre",
  "Just Kids Learning Place Pte Ltd",
  "Juying Primary School",
  "Juying Secondary School",
  "Juz Education",
  "K Sanctuary Pte Ltd",
  "K+ @ Jurong Spring",
  "K+ @ Preschool Moulmein",
  "K+ @ Serangoon North Village",
  "K+@Fernvale Child Development Centre",
  "KAAMP Kids Arts & Academics Mentorship",
  "Kampong Kapor Methodist Church Kindergarten",
  "Kampung Siglap Mosque Kindergarten",
  "Kanooka School House Pte Ltd",
  "Kay Poh Road Baptist Kindergarten",
  "Keming Primary School",
  "Kent Ridge Secondary School",
  "Key Elementary Eduhouse Pte Ltd",
  "Khaba Kindergarden",
  "Khalsa Kindergarten",
  "Kheng Cheng School",
  "Kiddiewinkie Place @ Environment Building",
  "Kiddy Ark Childcare & Development Centre",
  "Kiddy Art",
  "Kiddy Campus",
  "Kids n Kids School",
  "Kids And Kins Child Care Centre",
  "Kid's Colourbox Centre",
  "Kids' Corner Child Day Care Centre",
  "Kids Educare Pte. Ltd.",
  "Kids IQ Kindergarten",
  "Kids' Kingdom Child Development And Learning Centre",
  "Kids Mansion Childcare Pte. Ltd.",
  "Kids Space Educare Pte Ltd",
  "Kids' Star Child Development Centre (S) Pte Ltd",
  "Kidscampus Tanjong Pagar Llp",
  "Kidskingdom @ Bedok Reservoir Llp",
  "Kids'Kingdom CDLC",
  "Kidskingdom Pte. Ltd.",
  "Kidsville Child Care & Development Centre",
  "Kidszone Childcare And Development Centre",
  "Kidszone Kindergarten",
  "Kidszone Kindergarten / Child Care Centre",
  "Kidz Castle Educare Pte. Ltd.",
  "Kidz Kulture Schoolhouse Pte. Ltd.",
  "Kidz Meadow Childcare & Development Centre",
  "Kidz Meadow Childcare & Development Centre @ Buangkok Ridge",
  "Kidz Meadow Kindergarten",
  "Kidzmonte Child Development Centre",
  "Kidzone Childcare Centre @ Jurong Pte. Ltd.",
  "Kidzpro Llp",
  "Kikkoman Trading Asia Pte Ltd",
  "Kim Keat Christian Church Kindergarten",
  "Kinder Corner 2",
  "Kindercampus (S) Pte Ltd",
  "Kindercare Centre @ 3A Pte. Ltd.",
  "Kindercare Centre Llp",
  "Kindercom Educare",
  "Kindergarten@An-Nahdhah",
  "Kinderland (Choa Chu Kang) Kindergarten",
  "Kinderland Child Care Centre (Woodlands)",
  "Kinderland Childcare Centre (Singapore Polytechnic)",
  "Kinderland Childcare Centre / Pre School / Kindergarten",
  "Kinderland Educare Services Pte Ltd",
  "Kinderland Preschool (Marine Parade)",
  "Kinderland Preschool (Pandan Valley)",
  "Kinderland Preschool (Serangoon)",
  "Kinderland Preschool (Woodlands)",
  "Kinderland Preschool (Yio Chu Kang)",
  "Kinderland Preschools (Pandan Valley)",
  "Kinderland Preschools (Yio Chu Kang)",
  "Kinderstars One-stop Learning Centre",
  "Kindertown Educare Pte. Ltd.",
  "Kinderyears Childcare",
  "Kinderyears Childcare & Development Centre",
  "Kinsland Educare",
  "Kits4kids Foundation Pte Ltd",
  "Kits4Kids Kindergarten",
  "Knowledge Frontier Centre",
  "Kong Hwa School",
  "Kranji Primary School",
  "Kranji Secondary School",
  "Kuo Chuan Presbyterian Primary School",
  "Kuo Chuan Presbyterian Secondary School",
  "Kyra Preschool Pte Ltd",
  "L' Kids @ Work Pte Ltd",
  "La Petite Montessori",
  "Lady Jayne Children'S Development Centre",
  "Lakeside Primary School",
  "Learning Adventure",
  "Learning Hub@Graceland Pte. Ltd.",
  "Learning Kidz @ Kovan Pte. Ltd.",
  "Learning Kidz Child Development Centre Llp",
  "Learning Kidz Educampus Pte. Ltd.",
  "Learning Kidz Educare Pte. Ltd.",
  "Learning Kidz Educentre Pte. Ltd.",
  "Learning Kidz Eduhub Pte Ltd",
  "Learning Seeds Child Development Centre @ Gek Poh Pte. Ltd.",
  "Learning Seeds Child Development Centre Pte.Ltd.",
  "Learning Vision @ Work Pte .Ltd.",
  "Learning Vision Childcare / Pre-School",
  "Learninghill Educare Pte Ltd",
  "Learninghill Pasir Ris Pte Ltd",
  "Lianhua Primary School",
  "Licdo Preschool Pte Ltd",
  "Licdo Preschool Team Pte. Ltd.",
  "Life Child Care Centre",
  "Life Journiz Pte. Ltd.",
  "Li'L Einsteins Early Learning Centre Pte. Ltd.",
  "Little Angels Childcare And Development Centre (S) Pte Ltd",
  "Little Beez Playskool",
  "Little Big Pre-School Pte. Ltd.",
  "Little Champs Schoolhouse (Montessori) Pte. Ltd.",
  "Little Dolphins Playskool",
  "Little Fairyland Child Care & Development Centre",
  "Little Fellow Childcare",
  "Little Footies Pte. Ltd.",
  "Little Footies Schoolhouse Pte. Ltd.",
  "Little Footprints Schoolhouse",
  "Little Footprints Schoolhouse (366) Pte. Ltd.",
  "Little Footprints Schoolhouse (Woodland) Pte. Ltd.",
  "Little Footprints Schoolhouse (Y388) Pte. Ltd.",
  "Little Footprints Schoolhouse (Yishun) Pte. Ltd.",
  "Little Genius Educare Centre",
  "Little Greenhouse",
  "Little Greenhouse @ Bukit Batok Pte Ltd",
  "Little Greenhouse @ Joo Seng Pte. Ltd.",
  "Little Greenhouse @ Jw Pte. Ltd.",
  "Little Greenhouse @ S540 Pte Ltd",
  "Little Greenhouse @ S553 Pte Ltd",
  "Little Greenhouse Childcare & Development Pte. Ltd.",
  "Little Hands Montessori Kindergarten",
  "Little Hands Pte Ltd",
  "Little Harvard International Preschool",
  "Little Koala Pte. Ltd.",
  "Little Leader Kindergarten",
  "Little Learners Preschool Pte Ltd",
  "Little Mighty Me Pte. Ltd.",
  "Little Oaks Montessori Kindergarten",
  "Little Ones Kindergarten",
  "Little Palm Pre-school Pte Ltd",
  "Little Palm Pre-School Pte Ltd",
  "Little Raffles School House Pte. Ltd.",
  "Little Raindrops Montessori Pre-school",
  "Little Skool House",
  "Little Skool House @Nus",
  "Little Skool House International",
  "Little Talent Childcare & Development Centre",
  "Little Treasures Childcare(Woodlands) Pte. Limited",
  "Little Treasures Kindergarten",
  "Little Tree House",
  "Little Tree House @ Sembawang Llp",
  "Little Tree House Llp",
  "Little Village On The Grange",
  "Little Wings Childcare and Learning Center",
  "Little Wonders Montessori Childcare (Jurong West) Pte. Ltd.",
  "Little Wonders Montessori Childcare (Woodlands) Pte. Ltd.",
  "Little Wonders Montessori Childcare @Jalan Remaja",
  "Little Wonders Montessori Childcare Pte Ltd",
  "Little Woods Montessori Preschool",
  "Little World Child Care And Development Centre Pte Ltd",
  "LittleSparks Montessori Kindergarten",
  "Living Sanctuary Kindergarten",
  "Lorna Whiston Pre-School",
  "Lorna Whiston Pre-School",
  "Lorna Whiston Pre-school Education Pte Ltd",
  "Lorna Whiston Schools Pte Ltd",
  "Loveshine Childcare Pte. Ltd.",
  "Loveshine Montessori East Coast Children'S House",
  "Loveshine Montessori Schoolhouse Pte. Ltd.",
  "Loving Child Care Centre",
  "Lovingkids Childcare",
  "Lovingkids Childcare",
  "Loyang Primary School",
  "Loyang Secondary School",
  "Lumiere Montessori House",
  "Lutheran Child Care Centre",
  "Macpherson Moral Child Care Centre",
  "Macpherson Primary School",
  "Macpherson Secondary School",
  "Macpherson Sheng Hong Childcare Centre",
  "Macpherson Shenghong Child Care",
  "Madrasah Al-Irsyad Al-Islamiah",
  "Madrasah Al-Maarif Al-Islamiah",
  "Magdalene's Kindergarten",
  "Maha Bodhi School (Primary)",
  "Mahad Al - Nasry Kindergarten",
  "Mahad Al-Nasry @ Sengkang Kindergarten",
  "Malay Youth Literary Association Kindergarten",
  "Mandarin Gardens Kindergarten",
  "Manhattan Readers Club Pte Ltd",
  "Manjusri Secondary School",
  "Marine Parade - Aspiration Child Care Centre",
  "Maris Kidz Schoolhouse",
  "Maris Kindergarten",
  "Maris Stella High School (Pri & Sec)",
  "Maris Stella Kindergarten",
  "Marsiling Primary School",
  "Marsiling Secondary School",
  "Marvel Kidsworld",
  "Marymount Convent School (Pri)",
  "Marymount Kindergarten",
  "Masterpiece Child Education Pte Ltd",
  "Masterpiece Childcare",
  "Mavel Kidsworld Pre-School",
  "chung cheng",
  "Mayflower Secondary School",
  "Mee Toh School (Primary)",
  "Meridian Primary School",
  "Merilyn Child Care Centre",
  "Merilyn Child Care Centre Pte Ltd",
  "Merry Kids Child Care",
  "Metamorphosis Kindergarten",
  "Methodist Church Of The Incarnation (MCI)",
  "Methodist Girls' School (Primary)",
  "Methodist Girls' School (Secondary)",
  "MFC Kindergarten",
  "MFC Kindergarten (Broadrick Road)",
  "MFC Kindergarten (Newton)",
  "Milagros De Montessori Pte Ltd",
  "MindChamps PreSchool",
  "Mindchamps Preschool @ Bishan Pte. Ltd.",
  "Mindchamps Preschool @ Bukit Timah Pte. Limited",
  "Mindchamps Preschool @ Changi Business Park Pte. Ltd.",
  "Mindchamps Preschool @ City Square Pte. Ltd",
  "Mindchamps Preschool @ Jtc Summit Pte. Ltd.",
  "Mindchamps Preschool @ Jurong West Pte. Ltd.",
  "Mindchamps Preschool @ Mbc Pte. Ltd.",
  "Mindchamps Preschool @ Paragon Pte. Limited",
  "Mindchamps Preschool @ Serangoon Pte. Limited",
  "Mindchamps Preschool @ Tampines Central 6 Pte. Ltd.",
  "Mindchamps Preschool Pte. Limited",
  "Mindchamps Preschool@Fusion Pte. Ltd.",
  "Mini World Child Care & Development Centre Pte Ltd",
  "MMI Preschool & Childcare (Modern Montessori International Group)",
  "Modern Montessori Child Care & Development Centre Pte Ltd",
  "Modern Montessori Community Childcare Centre",
  "Modern Montessori Kindergarten",
  "Modern Montessori Pre-School Centre / Kindergarten",
  "Monfort Junior School",
  "Monte Kidz World",
  "Monte Kidz World (Hougang)",
  "Monte Kidz World (Pasir Ris)",
  "Montessori @ Graceland Pte Ltd",
  "Montessori Children's House Kindergarten",
  "Montessori Children's House Pte Ltd",
  "Montfort Boys School",
  "Montfort Junior School",
  "Montfort Secondary School",
  "Moral Child Development Centre (Chee Hoon Kog)",
  "Moral Child Development Centre (Macpherson)",
  "Moral Child Development Centre (Membina)",
  "Moriah Child Care And Development Centre",
  "Moriah School House",
  "Moriah Schoolhouse Llp",
  "Morning Glory Playhouse & Enrichment School",
  "Mothergoose Preschool And Child Development Centre",
  "Motherwell Child Care Centre",
  "Mount Sinai Preschool Pte Ltd",
  "MRC Kindergarten (Downtown East)",
  "Muhammadiyah Kindergarten",
  "Mujahidin Mosque Kindergarten",
  "Mulberry Learning Academy Pte. Ltd.",
  "Mulberry Learning Centre",
  "Mulberry Learning Centre @ Bukit Merah Pte. Ltd.",
  "Mulberry Learning Centre @ Raffles Place Pte. Ltd.",
  "Mulberry Learning Centre International Pte. Ltd.",
  "Mulberry Learning Centre Llp",
  "Mulberry Learning Centre@Tanjong Pagar Pte. Ltd",
  "Murni Childcare",
  "Muru Golf Academy Pte Ltd",
  "Mustard Seeds @ Hougang Pte. Ltd.",
  "Mustard Seeds Learning Centre Pte Ltd",
  "Mustard Seeds Learning Centre Pte Ltd",
  "My First Classroom",
  "My First Classroom @Siglap",
  "My First School",
  "My First Skool",
  "My Jolly Educare Pte Ltd",
  "My Little Campus (Bishan)",
  "My Little Campus (Yishun)",
  "My Little Campus Childcare & Pre-School",
  "My Little Gems Kindergarten",
  "My Little Genius Pte Ltd",
  "My Montessori My World Kindergarten",
  "My World @ Ang Mo Kio",
  "My World @ Bishan",
  "My World @ Bukit Panjang",
  "My World @ Hougang",
  "My World @ Pasir Ris",
  "My World @ Simei",
  "My World @ Sims",
  "My World @ Stevens",
  "My World @ Tampines Central",
  "My World @ Tampines Changkat",
  "My World @ Woodlands",
  "My World @ Yishun",
  "NAFA (SYT) Arts Kindergarten",
  "Nan Chiau High School",
  "Nan Chiau Primary School",
  "Nan Hua High School",
  "Nan Hua Primary School",
  "Nanyang Children Skool",
  "Nanyang Girls' High School",
  "Nanyang Kindercare Centre",
  "Nanyang Kindergarten",
  "Nanyang Primary School",
  "National Junior College",
  "Nativity Church Kindergarten",
  "Naval Base Primary School",
  "Naval Base Secondary School",
  "New Life Childcare",
  "New Life Childcare & Pre-School (Kindergarten)",
  "New Life Kindergarten",
  "New Town Primary School",
  "New Town Secondary School",
  "Newton Kindergarten / Pre-School",
  "Nexus International School Pte Ltd",
  "Nexus International School Singapore (NISS)",
  "Nexus Kindergarten",
  "Ngee Ann Primary School",
  "Ngee Ann Secondary School",
  "Nissi Childcare Pte Ltd",
  "Noah'S Ark Childcare & Development Centre",
  "Nobleland Arts N Learning Place @ Bishan Pte. Ltd.",
  "Nobleland Arts N Learning Place Pte. Limited",
  "North Spring Primary School",
  "North View Primary School",
  "North View Secondary School",
  "North Vista Primary School",
  "North Vista Secondary School",
  "Northbrooks Secondary School",
  "Northland Primary School",
  "Northland Secondary School",
  "NPS International",
  "NTUC ChildCare Centre",
  "Nurturestars Child Care",
  "NurtureStars Childcare Centre",
  "Nurturestars Pte. Ltd.",
  "Nurturing Roots Montessori Preschool",
  "Nus High School Of Mathematics And Science",
  "Nuture Tots Pre-School Pte. Ltd.",
  "Odyssey The Global Preschool Pte Ltd",
  "Odyssey The Global Preschool Pte Ltd",
  "Olyumpus Student Care Centre",
  "One World International School Singapore- (OWIS)",
  "Opera Estate Primary School",
  "Orange Tree Pre-School",
  "Orchid Park Secondary School",
  "Our Future Prodigies Pte. Ltd.",
  "Our Juniors Global Schoolhouse Pte. Ltd.",
  "Our Juniors' Schoolhouse",
  "Our Juniors' Schoolhouse @ Balestier Pte. Ltd.",
  "Our Juniors' Schoolhouse @ Choa Chu Kang Pte. Ltd.",
  "Our Juniors' Schoolhouse @ G'Kids Pte. Ltd.",
  "Our Juniors' Schoolhouse @ Telok Kurau Pte. Ltd.",
  "Our Juniors' Schoolhouse @ Ubi Techpark Pte. Ltd.",
  "Our Lady Star Of The Sea Child Care Centre",
  "Our Lady Star Of The Sea Child Care Centre",
  "Our Lady Star Of The Sea Childcare Centre",
  "Our Little Learning House 2",
  "Outram Road, Sgh Sisters Quarters, Singapore 169608",
  "Outram Secondary School",
  "Overseas Family School (OFS)",
  "Oxford Educare Pte Ltd",
  "P & J Kindergarten",
  "Pafed Pte Ltd",
  "Palm View Primary School",
  "Paper Scissors Stone Montessori Kindergarten",
  "Park View Primary School",
  "Park West Creche",
  "Pasir Panjang Hill Church Kindergarten",
  "Pasir Ris Crest Secondary School",
  "Pasir Ris Primary School",
  "Pasir Ris Secondary School",
  "Pathlight School (Secondary)",
  "Pathlight School (Primary)",
  "Pat's School House",
  "Pat'S Schoolhouse Pte Ltd",
  "Pat'S Schoolhouse Pte. Ltd.",
  "Paya Lebar Methodist Church Covenant Kindergarten",
  "Paya Lebar Methodist Girls' School (Primary)",
  "Paya Lebar Methodist Girls' School (Secondary)",
  "PCF (PAP Community Foundation)",
  "PCF @ Acorn Champs",
  "PCF @ Admiralty Blk 501",
  "PCF @ Admiralty Blk 556-557",
  "PCF @ Admiralty Blk 581",
  "PCF @ Admiralty Blk 624B",
  "PCF @ Admiralty Blk 687B",
  "PCF @ Ang Mo Kio-Hougang Blk 632",
  "PCF @ Ang Mo Kio-Hougang Blk 929",
  "PCF @ Ang Mo Kio-Hougang Blk 936-937",
  "PCF @ Ang Mo Kio-Hougang Blk 970",
  "PCF @ Ayer Rajah Blk 48-41",
  "PCF @ Bedok Blk 30",
  "PCF @ Bedok Reservoir-Punggol Blk 414",
  "PCF @ Bedok Reservoir-Punggol Blk 508",
  "PCF @ Bedok Reservoir-Punggol Blk 713",
  "PCF @ Bishan East Blk 144",
  "PCF @ Bishan East Blk 197-115-196",
  "PCF @ Bishan North Blk 231",
  "PCF @ Bishan North Blk 257",
  "PCF @ Bishan North Blk 441",
  "PCF @ Boon Lay Blk 207",
  "PCF @ Boon Lay Blk 262",
  "PCF @ Boon Lay Blk 631",
  "PCF @ Braddell Heights Blk 1",
  "PCF @ Braddell Heights Blk 307-235",
  "PCF @ Bukit Batok Blk 148",
  "PCF @ Bukit Batok Blk 180",
  "PCF @ Bukit Batok East Blk 208",
  "PCF @ Bukit Batok East Blk 241",
  "PCF @ Bukit Batok East Blk 254",
  "PCF @ Bukit Batok East Blk 285C-285D",
  "PCF @ Bukit Batok East Blk 289G",
  "PCF @ Bukit Batok Sparkletots Child Care Centre",
  "PCF @ Bukit Gombak Blk 535",
  "PCF @ Bukit Panjang Blk 111-110",
  "PCF @ Bukit Panjang Blk 226",
  "PCF @ Bukit Panjang Blk 254",
  "PCF @ Bukit Panjang Blk 412",
  "PCF @ Bukit Panjang Blk 422-420",
  "PCF @ Bukit Timah Blk 12",
  "PCF @ Bukit Timah Blk 319",
  "PCF @ Bukit Timah Block 307",
  "PCF @ Buona Vista Blk 4",
  "PCF @ Canberra Blk 330-319-327-328",
  "PCF @ Canberra Blk 413 - 405",
  "PCF @ Canberra Blk 468D-468C",
  "PCF @ Canberra Blk 487-488",
  "PCF @ Cashew",
  "PCF @ Cashew Blk 545-544",
  "PCF @ Chai Chee",
  "PCF @ Changi Simei Blk 119",
  "PCF @ Changi Simei Blk 131",
  "PCF @ Changi Simei Blk 141",
  "PCF @ Changi Simei Blk 233",
  "PCF @ Cheng San-Seletar Blk 435",
  "PCF @ Cheng San-Seletar Blk 533",
  "PCF @ Chong Pang Blk 107",
  "PCF @ Chong Pang Blk 122",
  "PCF @ Chong Pang Blk 317-315",
  "PCF @ Chong Pang Block 309",
  "PCF @ Chong Pang Sparkle Tots Child Care Centre",
  "PCF @ Chua Chu Kang Blk 10-8",
  "PCF @ Chua Chu Kang Blk 123-208",
  "PCF @ Chua Chu Kang Blk 484-484A",
  "PCF @ Chua Chu Kang Blk 786-787-788-792",
  "PCF @ Clementi Childcare Centre Little Wings (Blk 330)",
  "PCF @ Clementi Childcare Centre Little Wings (Blk 462A)",
  "PCF @ Eunos Blk 122",
  "PCF @ Eunos Blk 616",
  "PCF @ Eunos Blk 629",
  "PCF @ Eunos Blk 650",
  "PCF @ Feng Shan Sparkletots Child Care Centre",
  "PCF @ Fengshan Blk 103",
  "PCF @ Fengshan Blk 126",
  "PCF @ Geylang Serai Blk 11",
  "PCF @ Geylang Serai Blk 15",
  "PCF @ Geylang Serai Blk 311",
  "PCF @ Geylang Serai Blk 326-341",
  "PCF @ Geylang Serai Blk 412-413",
  "PCF @ Geylang Serai Blk 9",
  "PCF @ Hong Kah North Blk 276D",
  "PCF @ Hong Kah North Blk 315-319",
  "PCF @ Hong Kah North Blk 338",
  "PCF @ Hong Kah North Blk 750-749",
  "PCF @ Hougang Blk 328-708",
  "PCF @ Jalan Besar Blk 12",
  "PCF @ Jalan Besar Blk 806",
  "PCF @ Jln Kayu Blk 518-520-522",
  "PCF @ Jln Kayu Blk 556",
  "PCF @ Joo Chiat Blk 15",
  "PCF @ Jurong Central Blk 308",
  "PCF @ Jurong Central Blk 490",
  "PCF @ Jurong Spring Blk 465",
  "PCF @ Jurong Spring Blk 515",
  "PCF @ Jurong Spring Blk 527",
  "PCF @ K+ @ Palette Zone",
  "PCF @ K+ Centre @ Woodlands Blk 649",
  "PCF @ K+ Super Juniors' Early Learning Centre",
  "PCF @ Kaki Bukit Blk 519-545",
  "PCF @ Kaki Bukit Blk 526",
  "PCF @ Kampong Chai Chee Blk 135",
  "PCF @ Kampong Chai Chee Blk 409",
  "PCF @ Kampong Chai Chee Blk 775",
  "PCF @ Kampong Chai Chee Infant & Child Care Centre",
  "PCF @ Kampong Glam Blk 2",
  "PCF @ Kampong Glam Blk 462-3",
  "PCF @ Keat Hong Blk 295",
  "PCF @ Keat Hong Blk 411",
  "PCF @ Kebun Baru Blk 112",
  "PCF @ Kebun Baru Blk 235",
  "PCF @ Kebun Baru Sparkletots Child Care Centre",
  "PCF @ Kembangan-Chai Chee Blk 116",
  "PCF @ Kembangan-Chai Chee Blk 59",
  "PCF @ Kindercare @ Whampoa",
  "PCF @ Kolam Ayer Blk 52",
  "PCF @ Kolam Ayer Blk 64",
  "PCF @ Kreta Ayer-Kim Seng Blk 79",
  "PCF @ Little Wings Buona Vista Childcare Blk 32",
  "PCF @ Little Wings Jurong Central Childcare Centre",
  "PCF @ Little Wings Jurong Spring Child Care Blk 455",
  "PCF @ Little Wings Yuhua Childcare (Blk 108)",
  "PCF @ Little Wings Yuhua Childcare Centre (Blk 233)",
  "PCF @ Little Wings Yuhua Childcare Centre (Blk 264)",
  "PCF @ Macpherson Blk 108-107",
  "PCF @ Macpherson Blk 31",
  "PCF @ Macpherson Blk 54-53",
  "PCF @ Marine Parade Blk 46-47",
  "PCF @ Marsiling Blk 15",
  "PCF @ Marsiling Blk 302",
  "PCF @ Marsiling Blk 32",
  "PCF @ Marsiling Blk 334",
  "PCF @ Moulmein",
  "PCF @ Moulmein Blk 10",
  "PCF @ Mountbatten Blk 14",
  "PCF @ Mountbatten Blk 51",
  "PCF @ Nanyang Blk 813",
  "PCF @ Nanyang Blk 825",
  "PCF @ Nanyang Blk 934",
  "PCF @ Nee Soon Central Blk 367",
  "PCF @ Nee Soon Central Blk 609",
  "PCF @ Nee Soon Central Blk 646-644",
  "PCF @ Nee Soon Central Blk 737-738",
  "PCF @ Nee Soon East Blk 227-223-225-234",
  "PCF @ Nee Soon East Blk 277",
  "PCF @ Nee Soon East Blk 312",
  "PCF @ Nee Soon East Blk 408",
  "PCF @ Nee Soon East Sparkletots Childcare Centre",
  "PCF @ Nee Soon East Sparkletots Childcare Centre",
  "PCF @ Nee Soon South Blk 794",
  "PCF @ Nee Soon South Blk 879-852-874-875",
  "PCF @ Nee Soon South Sparkletots Child Care Centre",
  "PCF @ Pasir Ris East Blk 216-213",
  "PCF @ Pasir Ris East Blk 426",
  "PCF @ Pasir Ris West Blk 517-511",
  "PCF @ Pasir Ris West Blk 738-737",
  "PCF @ Paya Lebar Blk 125",
  "PCF @ Paya Lebar Blk 23",
  "PCF @ Pioneer Blk 642",
  "PCF @ Pioneer Blk 654B",
  "PCF @ Pioneer Blk 661B-662C",
  "PCF @ Pioneer Blk 662B",
  "PCF @ Pioneer Blk 665A-665B",
  "PCF @ Potong Pasir Blk 143",
  "PCF @ Punggol Central Blk 206A-206D",
  "PCF @ Punggol Central Blk 231-232",
  "PCF @ Punggol Central Blk 262A",
  "PCF @ Punggol Central Blk 292B",
  "PCF @ Punggol East Blk 103",
  "PCF @ Punggol East Blk 124A-124",
  "PCF @ Punggol East Blk 143-147",
  "PCF @ Punggol East Blk 187B-187A",
  "PCF @ Punggol North Blk 105D",
  "PCF @ Punggol North Blk 171C-171B",
  "PCF @ Punggol North Blk 174D-176D",
  "PCF @ Punggol South Blk 277B",
  "PCF @ Punggol South Blk 452",
  "PCF @ Punggol South Blk 535-542",
  "PCF @ Punggol West Blk 271A",
  "PCF @ Punggol West Blk 303A",
  "PCF @ Queenstown Blk 3",
  "PCF @ Queenstown Blk 46-2-46-3",
  "PCF @ Queenstown Blk 53A",
  "PCF @ Radin Mas Blk 108",
  "PCF @ Radin Mas Blk 21",
  "PCF @ Radin Mas Blk 70A",
  "PCF @ Radin Mas Blk 87",
  "PCF @ Segar Meadows",
  "PCF @ Sembawang Blk 507D-501D-503C",
  "PCF @ Sembawang Blk 730",
  "PCF @ Sembawang Blk 755",
  "PCF @ Sembawang Blk 786B",
  "PCF @ Sembawang Blk 789-797-788",
  "PCF @ Sengkang West Blk 303A-302A",
  "PCF @ Sengkang West Blk 436D-436A",
  "PCF @ Sengkang West Blk 443",
  "PCF @ Serangoon Blk 125",
  "PCF @ Serangoon Blk 141",
  "PCF @ Serangoon Blk 226",
  "PCF @ Siglap Blk 170",
  "PCF @ Simei",
  "PCF @ Sparkle Tots Punggol Central (Block 259B)",
  "PCF @ Sparkletots",
  "PCF @ Sparkletots (Admiralty)",
  "PCF @ Sparkletots (Chua Chu Kang)",
  "PCF @ Sparkletots (Eunos)",
  "PCF @ Sparkletots (Hong Kah North)",
  "PCF @ Sparkletots (Jalan Kayu)",
  "PCF @ Sparkletots (Nanyang)",
  "PCF @ Sparkletots (Nee Soon East)",
  "PCF @ Sparkletots (Nee Soon South Blk 446)",
  "PCF @ Sparkletots (Nee Soon South)",
  "PCF @ Sparkletots (Pasir Ris East)",
  "PCF @ Sparkletots (Pasir Ris West)",
  "PCF @ Sparkletots (Queenstown - Mei Ling)",
  "PCF @ Sparkletots (Tampines West)",
  "PCF @ Sparkletots @ Marsiling",
  "PCF @ Sparkletots Bedok",
  "PCF @ Sparkletots Bishan North",
  "PCF @ Sparkletots Boon Lay (Blk 627)",
  "PCF @ Sparkletots Bukit Batok (Blk 206)",
  "PCF @ Sparkletots Bukit Batok (Blk178)",
  "PCF @ Sparkletots Bukit Batok East Child Care",
  "PCF @ Sparkletots Bukit Gombak",
  "PCF @ Sparkletots Cheng San - Seletar",
  "PCF @ Sparkletots Child Care Centre (Bishan East)",
  "PCF @ Sparkletots Childcare Centre @Cashew",
  "PCF @ Sparkletots Chong Pang (Block 115B)",
  "PCF @ Sparkletots Eunos (Blk 620)",
  "PCF @ Sparkletots Fengshan (Blk 108)",
  "PCF @ Sparkletots Geylang Serai",
  "PCF @ Sparkletots Hong Kah North (Blk 275A)",
  "PCF @ Sparkletots Hong Kah North (Blk 419)",
  "PCF @ Sparkletots Hong Kah North (Blk 748)",
  "PCF @ Sparkletots Kaki Bukit (Blk 525)",
  "PCF @ Sparkletots Kampong Chai Chee (Blk 424)",
  "PCF @ Sparkletots Keat Hong",
  "PCF @ Sparkletots Keat Hong (Blk 435)",
  "PCF @ Sparkletots Kebun Baru",
  "PCF @ Sparkletots Kembangan Chai Chee (Blk 32)",
  "PCF @ Sparkletots Kolam Ayer",
  "PCF @ Sparkletots Nee Soon South (Blk 852)",
  "PCF @ Sparkletots Paya Lebar",
  "PCF @ Sparkletots Pioneer (Blk 694)",
  "PCF @ Sparkletots Pioneer (Block 987D)",
  "PCF @ Sparkletots Punggol Central",
  "PCF @ Sparkletots Punggol Central (Block 298B)",
  "PCF @ Sparkletots Punggol East (Blk 123A)",
  "PCF @ Sparkletots Punggol North (Blk 172B)",
  "PCF @ Sparkletots Punggol South",
  "PCF @ Sparkletots Punggol West (Blk 602)",
  "PCF @ Sparkletots Radin Mas",
  "PCF @ Sparkletots Sembawang (Blk 504A)",
  "PCF @ Sparkletots Sengkang West",
  "PCF @ Sparkletots Siglap",
  "PCF @ Sparkletots Tanglin-Cairnhill (Blk 59)",
  "PCF @ Sparkletots Teck Ghee (Blk 310A)",
  "PCF @ Sparkletots Toa Payoh Central",
  "PCF @ Sparkletots Ulu Pandan [Blk 15]",
  "PCF @ Sparkletots West Coast",
  "PCF @ Sparkletots Woodlands (Blk 801)",
  "PCF @ Sparkletots Yew Tee",
  "PCF @ Taman Jurong Blk 161",
  "PCF @ Taman Jurong Blk 352-355",
  "PCF @ Tampines 261",
  "PCF @ Tampines Central Blk 713-714",
  "PCF @ Tampines Central Blk 725",
  "PCF @ Tampines Central Blk 856-854",
  "PCF @ Tampines Central Blk 858-860",
  "PCF @ Tampines Changkat Blk 106",
  "PCF @ Tampines Changkat Blk 284-285",
  "PCF @ Tampines Changkat Blk 318",
  "PCF @ Tampines East Blk 261",
  "PCF @ Tampines East Blk 262",
  "PCF @ Tampines East Blk 358",
  "PCF @ Tampines East Blk 385",
  "PCF @ Tampines North Blk 443-444",
  "PCF @ Tampines North Blk 483",
  "PCF @ Tampines West Blk 140-938",
  "PCF @ Tampines West Blk 887",
  "PCF @ Tanglin-Cairnhill Blk 124A",
  "PCF @ Tanjong Pagar-Tiong Bahru Blk 130 - 127",
  "PCF @ Tanjong Pagar-Tiong Bahru Blk 1E",
  "PCF @ Tanjong Pagar-Tiong Bahru Blk 6",
  "PCF @ Teck Ghee Blk 301",
  "PCF @ Teck Ghee Blk 319",
  "PCF @ Teck Ghee Blk 415",
  "PCF @ Teck Ghee Blk 466",
  "PCF @ Telok Blangah Blk 2",
  "PCF @ Telok Blangah Blk 78A",
  "PCF @ The Acorn",
  "PCF @ Thomson-Toa Payoh Blk 205",
  "PCF @ Thomson-Toa Payoh Blk 313",
  "PCF @ Toa Payoh Central Blk 157",
  "PCF @ Toa Payoh Central Blk 97",
  "PCF @ Toa Payoh East - K+ Blossom @ 232",
  "PCF @ Toa Payoh East Blk 247",
  "PCF @ Toa Payoh East Blk 45",
  "PCF @ Toa Payoh East Blk 6-231-232",
  "PCF @ Towner Road",
  "PCF @ Ulu Pandan Blk 24",
  "PCF @ West Coast Blk 702",
  "PCF @ West Coast Blk 728",
  "PCF @ Whampoa Blk 105",
  "PCF @ Whampoa Blk 106",
  "PCF @ Whampoa Blk 112",
  "PCF @ Whampoa Blk 85",
  "PCF @ Woodgrove Blk 101",
  "PCF @ Woodgrove Blk 364",
  "PCF @ Woodgrove Blk 899B",
  "PCF @ Woodlands Blk 601",
  "PCF @ Woodlands Blk 652",
  "PCF @ Woodlands Blk 824",
  "PCF @ Woodlands Blk 853",
  "PCF @ Woodlands Blk 875",
  "PCF @ Woodlands Sparkletots Child Care Centre",
  "PCF @ Yew Tee Blk 543-540",
  "PCF @ Yew Tee Blk 608-607",
  "PCF @ Yew Tee Blk 625",
  "PCF @ Yew Tee Blk 682",
  "PCF @ Yew Tee Blk 690D-690C",
  "PCF @ Yew Tee Blk 690D-690C Pre-School",
  "PCF @ Yio Chu Kang Blk 121-214",
  "PCF @ Yio Chu Kang Blk 611",
  "PCF @ Yio Chu Kang Blk 644",
  "PCF @ Yuhua Blk 223A",
  "PCF @ Yuhua Blk 251-247",
  "PCF @ Yuhua Blk 317",
  "PCF @ Zhenghua Blk 455A",
  "PCF @ zhenghua blk 455a segar meadows",
  "PCF @ Zhenghua Blk 528-526-532",
  "PCF @ Zhenghua Blk 620-615-619",
  "PCF @ @Woodlands Kindercare",
  "PCF @ Admiralty",
  "PCF @ Bedok",
  "PCF @ Bukit Panjang",
  "PCF @ Canberra",
  "PCF @ Changi-Simei-Simei St 1",
  "PCF @ Hougang",
  "PCF @ Pioneer",
  "PCF @ Punggol Central",
  "PCF @ Punggol South-Sengkang Central-MCP",
  "PCF @ Serangoon",
  "PCF @ Tampines",
  "PCF @ Tampines Central-Tampines St 71",
  "PCF @ Toa Payoh Central-Lor 2 Toa Payoh",
  "PCS Ang Mo Kio Child Care Centre",
  "PCS Bukit Batok Centre",
  "PCS Ghim Moh Centre",
  "PCS Holland Village Centre",
  "PCS Hougang Centre",
  "PCS Jurong West Centre",
  "PCS Tampines Centre",
  "PCS Woodlands Centre",
  "PCS Yishun Child Care Centre",
  "Pearlbank Kindergarten",
  "Pebble Place Development Centre",
  "Pei Chun Public School (Primary)",
  "Pei Hwa Presbyterian Primary School",
  "Pei Hwa Secondary School",
  "Pei Tong Primary School",
  "Peicai Secondary School",
  "Peirce Secondary School",
  "Peiying Primary School",
  "Pentecost Methodist Church Kindergarten",
  "Phyllis' Riccia Educational Group Pte. Ltd.",
  "Phyllis' Riccia School",
  "Phyllis' Riccia School Pte Ltd",
  "Pibos Garden Playschool",
  "Pibos Garden Preschool",
  "Pibos Garden Pte. Ltd.",
  "Pickel Fence Day Care & Learnning Centre",
  "Picket Fence @ Tampines Pte. Ltd.",
  "Ping Yi Secondary School",
  "Pink Tower Montessori",
  "Pioneer Primary School",
  "Pioneer Secondary School",
  "Pitter Patter Child Care & Development Centre Pte Ltd",
  "Places For Childhood Pte Ltd",
  "Planet Montessori Pte. Ltd.",
  "Poi Ching School",
  "Poi Ching School (Primary)",
  "Pony Running",
  "PPIS Child Development Centre - Bedok Reservoir",
  "PPIS Child Development Centre - Bukit Batok",
  "PPIS Child Development Centre - Jurong",
  "PPIS Child Development Centre - Pasir Ris 2",
  "PPIS Child Development Centre - Pasir Ris1",
  "PPIS Child Development Centre - Sembawang Vista",
  "Praise Kids Educare Pte. Ltd.",
  "Praiseland Child Care And Learning Centre",
  "Precious Gems Child Development Centre",
  "Presbyterian Community Service Childcare Centre",
  "Presbyterian High School",
  "Pre-School By-The-Park (Dunearn) Pte Ltd",
  "Pre-school By-the-park Pte Ltd",
  "Pre-School By-The-Park Pte. Ltd.",
  "Preschool For Multiple Intelligences",
  "Preschool For Multiple Intelligences International Pte. Ltd.",
  "Preschool For Multiple Intelligences Pte. Ltd.",
  "Preschool Learning Academy @ Temasek Polytechnic",
  "Prince Siddhattha Child Care Centre",
  "Prince Siddhattha Child Care Centre",
  "Princess Elizabeth Primary School",
  "Pro-Active Mind Training Centre",
  "Prodigy Preschool",
  "Pro-teach Education (Khatib)",
  "Protherapist Academy Pte Ltd",
  "Pssmk Pte Ltd",
  "Pu Hui Kindergarten",
  "Pu Ti Child Care Centre",
  "Punggol Green Primary School",
  "Punggol Primary School",
  "Punggol Secondary School",
  "Punggol View Primary School",
  "Putra - Putri Kindergarten",
  "Q-dees Global Pte Ltd",
  "Qiaonan Primary School",
  "Qifa Primary School",
  "Qihua Primary School",
  "Queenstown Good Shepherd Kindergarten",
  "Queenstown Lutheran Church Kindergarten",
  "Queenstown Primary School",
  "Queenstown Secondary School",
  "Queensway Secondary School",
  "Radin Mas Primary School",
  "Raffles Early Learning Centre",
  "Raffles Early Learning Centre Pte. Ltd.",
  "Raffles Girls' Primary School",
  "Raffles Girls' School (Secondary)",
  "Raffles House @ Paya Lebar Private Limited",
  "Raffles House Preschool (Robin Close)",
  "Raffles House Preschool Pte Ltd",
  "Raffles Institution",
  "Raffles Montessori Kindergarten",
  "Raffles Montessori Kindergarten (Aljunied)",
  "Raffles Montessori Kindergarten (Telok Kurau)",
  "Rain Trees Kindergarten",
  "Rainbow Childcare Pte Ltd",
  "Rainbow Cove Educational Services Pte. Ltd.",
  "Rainbowland Learning Centre Pte Ltd",
  "Raising Champions Learning Cove Pte. Ltd.",
  "Ramakrishna Mission Sarada Kindergarten",
  "Red Schoolhouse Pte Ltd",
  "Red Swastika School (Pri)",
  "Red Swastika School (Primary)",
  "Regent Secondary School",
  "Rejoice Kidscampus",
  "Rh Preschool Pte Ltd",
  "Ripples Montessori Kindergarten",
  "Ripples Pre-school & Day Care Pte Ltd",
  "River Valley High School",
  "River Valley Primary School",
  "Riverlife Kindergarten",
  "Riverside Primary School",
  "Riverside Secondary School",
  "Rivervale Primary School",
  "Rosemary Hall Montessori Kindergarten",
  "Rosemary Hall Montessori Kindergarten (RHMK)",
  "Rosemount Kindergarten Pte Ltd",
  "Rosy Hearts Babies",
  "Rosyth Child Care",
  "Rosyth Child Care Centre",
  "Rosyth School (Primary)",
  "Royal Spa Academy Pte Ltd",
  "Rsh Kids Pte Ltd",
  "Rulang Primary School",
  "Safari House",
  "Safari House Preschool",
  "Safari House Preschool Jurong",
  "Saint Elmo'S Child Care",
  "Saint Elmo'S Pte Ltd",
  "Salvation Army Child Care Centre",
  "Sam'S Early Learning Centre Pte Ltd",
  "San Lorenzo Montessori",
  "Sarada Kindergarten",
  "Saraswathy Darma Muneeswaran Kindergarten",
  "Saraswathy Kindergarten",
  "Sasco Child Care Centre",
  "Sasco Child Care Centre (Bedok)",
  "School House @ Cabcy",
  "School House by the Garden",
  "School House Bythegarden @ Eunos Pte. Ltd.",
  "School House Bythegarden @ Thomson Pte Ltd",
  "School House Bythegarden Pte. Ltd.",
  "School Of Science And Technology, Singapore",
  "School Of The Arts, Singapore",
  "Schoolhouse By The Bay",
  "Schoolhouse By The Bay Pte. Ltd.",
  "Seedlings Montessori Alexandra Pte Ltd",
  "Seedlings Patch",
  "Seeds D Learning House",
  "Sembawang Primary School",
  "Sembawang Secondary School",
  "Seng Kang Primary School",
  "Seng Kang Secondary School",
  "Sengkang Green Primary School",
  "Sengkang Methodist Children Centre (SKMCC)",
  "Sengkang Methodist Children Centre (SKMCC)",
  "Serangoon Garden Secondary School",
  "Serangoon Secondary School",
  "Seventh-day Adventist Kindergarten",
  "Sgm Little Kidz @ Rivervale",
  "Sgm Murni",
  "Shalom Kindergarten",
  "Shaws Cdlc @ Braddell Heights Pte Ltd",
  "Shaws Cdlc @ Katong Private Limited",
  "Shaws Cdlc @ Mountbatten Pte Ltd",
  "Shaws Cdlc @ Mountbatten Pte. Ltd.",
  "Shaws Cdlc Preschool Pte. Ltd.",
  "Shaws Little League",
  "Sheffield Kidsworld (Cck) Pte. Ltd.",
  "Sheffield Kidsworld (Sk) Pte. Ltd.",
  "Sheffield Kidsworld (Wlds) Pte. Ltd.",
  "Shekinah Montessori House",
  "Sheng Hong Child Care Center",
  "Sherwood Childcare",
  "Sherwood Childcare Pte Ltd",
  "Shooting Star Child Development Centre",
  "Shuqun Primary School",
  "Shuqun Secondary School",
  "Si Ling Primary School",
  "Si Ling Secondary School",
  "Siglap Secondary School",
  "Singapore American School",
  "Singapore American School (SAS)",
  "Singapore Chinese Girls' Primary School",
  "Singapore Chinese Girls' Secondary School",
  "Singapore International School",
  "Singapore Japanese School",
  "Singapore Korean School(SKS)",
  "Singapore Montessori Kindergarten",
  "Singapore Spa Institute Pte Ltd",
  "Singapore Sports School",
  "Singquali Electronics & Automation",
  "SJI International School (SJII)",
  "Small World Educare Centre Pte. Ltd.",
  "Smart Junior Kindergarten",
  "Smart Kids Educare Llp",
  "Smart Kids Kindergarten",
  "Smart Kids Learning Place Pte Ltd",
  "Smart Kids Portsdown Pte. Ltd.",
  "Smart Learning Kindergarten",
  "Smartgro Tiny Tots",
  "Smartie Cottage",
  "Smartys Educare Pte Ltd",
  "Smartz Playhouse Pte. Ltd.",
  "Smiling Star Child Care Centre",
  "Smiling Star Singapore Pte. Ltd",
  "Soka Kindergarden",
  "Soka Kindergarten",
  "Sonrise Montessori",
  "Sonshine Child Care Centre",
  "South View Primary School",
  "Sparkle Stones Educare Centre",
  "Sparkle Tots Child Care Centre",
  "Sparkle Tots Child Care Centre Pcf Bishan-Toa Payoh North",
  "Sparkletots Child Care Centre - Keat Hong",
  "Sparkletots Child Care Centre (Alexandra)",
  "Sparkletots Child Care Centre (Braddell Heights)",
  "Sparkletots Child Care Centre (Choa Chu Kang)",
  "Sparkletots Child Care Centre (Fengshan)",
  "Sparkletots Child Development Centre",
  "Sparkletots Queenstown Child Care Centre",
  "S'pore Trs'Co-op Childcare",
  "Spring Brainy Kidz Pte. Ltd.",
  "Springdale Primary School",
  "Springfield Secondary School",
  "Sri Sathya Sai Kindergarten",
  "Sri Satja Sai Kindergaten",
  "St. Andrew'S Cathedral Child Development Centre",
  "St. Anthony'S Child Care Centre",
  "St. Andrew's Junior School",
  "St. Andrew's Secondary School",
  "St. Anne's Church Kindergarten",
  "St. Anthony'S Canossian Primary School",
  "St. Anthony'S Canossian Secondary School",
  "St. Anthony'S Primary School",
  "St. Francis Of Assisi Kindergarten",
  "St. Francis Of Assisi Kindergarten (Boon Lay)",
  "St. Francis Of Assisi Kindergarten (Jurong West)",
  "St. Francis Xavier Kindergarten",
  "St. Gabriel's Primary School",
  "St. Gabriel'S Secondary School",
  "St. Gerard'S School Llp",
  "St. Hilda's Church Kindergarten",
  "St. Hilda'S Primary School",
  "St. Hilda'S Secondary School",
  "St. James' Church Kindergarten",
  "St. James' Church Kindergarten (Harding)",
  "St. Joseph'S Church Childcare Centre",
  "St. Joseph's Church Kindergarten",
  "St. Joseph'S Institution",
  "St. Joseph'S Institution Junior",
  "St. Margaret'S Primary School",
  "St. Margaret'S Secondary School",
  "St. Patrick'S School",
  "St. Paul's Church Kindergarten",
  "St. Stephen'S School (Pri)",
  "St. Stephen'S School (Primary)",
  "St. Vincent De Paul Kindergarten",
  "Stamford American International School",
  "Stamford American International School Pte Ltd",
  "Stamford Primary School",
  "Star Kidscampus",
  "Star Learner Sembawang",
  "Star Learners @ Ang Mo Kio Pte. Ltd.",
  "Star Learners @ Bishan Pte. Ltd.",
  "Star Learners @ Bukit Batok Pte. Ltd.",
  "Star Learners @ Pasir Ris Pte. Ltd.",
  "Star Learners @ Sembawang Pte. Ltd.",
  "Star Learners @ Sengkang Pte. Ltd.",
  "Star Learners @ Simei Pte. Ltd.",
  "Star Learners Child Care Centre",
  "Star Learners Kingdergarten",
  "Star Learners Montessori @ Sembawang Pte. Ltd.",
  "Star Learners Montessori Pte. Ltd.",
  "Star Learners Pte Ltd",
  "Starland Kindergarten",
  "Starlearner Child Care Centre",
  "Step 1 Learning Centre",
  "Stepping Stones Child Care And Learning Centre",
  "Summer Kids Child Care",
  "Summer Montessori",
  "Sunflower Baby House Pte. Ltd.",
  "Sunflower Bambini Childcare Llp",
  "Sunflower Childcare & Development Centre Pte Ltd",
  "Sunflower Childcare Group / Pre-School",
  "Sunflower Kiddy House",
  "Sunflower Kiddy Star",
  "Sunflower Kinderhub @ Amk Llp",
  "Sunflower Kinderhub Llp",
  "Sunflower Little Champs",
  "Sunflower Preschool @ Balestier Pte. Ltd.",
  "Sunflower Preschool @ Pasir Ris Pte. Ltd.",
  "Sunflower Preschool @ Punggol Field Pte. Ltd.",
  "Sunflower Preschool @ Punggol Field Walk Pte. Ltd.",
  "Sunflower Schoolhouse Pte. Ltd.",
  "Sunflower Tinkleland Private Limited",
  "Sunflower Tots 'N' Kids Childcare And Development Centre",
  "Sunflower Tots 'N' Kids Pte. Ltd.",
  "Sunny Bunny Montessori Infant And Child Care Centre",
  "Sunny Bunny Montessori Infant And Child Care Centre",
  "Sunnytots Schoolhouse",
  "Sunshine Kids Care Centre",
  "Sunshine Kids Centre",
  "Sunshine Kids Educare Private Limited",
  "Sunshine Kindergarten (Watten)",
  "Super Genius Creative Learners LLP",
  "Super Me Learning Pte Ltd",
  "Superland Montessori Pre-school",
  "Swallows & Amazons Pte Ltd",
  "Swallows And Amazons Kindergarten",
  "Sweet Kids Child Care Centre",
  "Sweet Kids Childcare & Development Centre",
  "Sweetlands Childcare Clementi 607 Pte. Ltd.",
  "Sweetlands Childcare Farrer Rd 6 Pte. Ltd.",
  "Sweetlands Childcare Jw 484 Pte. Ltd.",
  "Sweetlands Childcare Punggol 612 Pte. Ltd.",
  "Sweetlands Childcare Woodlands 608 Pte. Ltd.",
  "Sweetlands Childcare Woodlands 643 Pte. Ltd.",
  "Sweetlands Childcare Woodlands 896B Pte. Ltd.",
  "Sweetlands Childcare Yishun 458 Pte. Ltd.",
  "Swiss Cottage Secondary School",
  "Swiss School Singapore (SSS)",
  "T United Montessori Plus Kindergarten",
  "T United World Education Group Pte Ltd",
  "Tabernacle Knowledge Edn Center",
  "Tai Pei Child Care Centre",
  "Talent Plus",
  "Tambalog...A Meeting Place",
  "Tampines North Primary School",
  "Tampines Primary School",
  "Tampines Secondary School",
  "Tanglin Secondary School",
  "Tanglin Trust School (TTS)",
  "Tanjong Katong Girls' School",
  "Tanjong Katong Primary School",
  "Tanjong Katong Secondary School",
  "Tao Nan School",
  "Tao Nan School (Primary)",
  "Teck Ghee Primary School",
  "Teck Whye Primary School",
  "Teck Whye Secondary School",
  "Teeny Weeny Child Care Centre",
  "Telok Kurau Primary School",
  "Temasek Junior College",
  "Temasek Primary School",
  "Temasek Secondary School",
  "Tenderkids Child Care & Development Centre",
  "The Acorn Child Care",
  "The Acorn Child Care Centre",
  "The Ascension Kindergarten",
  "The Blue House Nursery Pte Ltd",
  "The Capstone Kindergarten",
  "The Caterpillar'S Cove Pte. Ltd.",
  "The Children'S Campus",
  "The Children'S Discovery Centre Pte Ltd",
  "The Children'S Place Pte Ltd",
  "The Discovery Place",
  "The Experiential Learning Centre Pte Ltd",
  "The Garden House Preschool",
  "The Kiddiwinkie Place (Env) Pte. Ltd.",
  "The Kiddiwinkie Place @ Sims Pte. Ltd.",
  "The Kiddiwinkie Place Pte Ltd",
  "The Kids' Inn",
  "The Kids' Inn Joo Chiat",
  "The Kidz Zone Pte Ltd",
  "The Learning Campus Preschool Pte. Ltd.",
  "The Learning Ladder",
  "The Learning Ladders Pre-School",
  "The Learning Vine Childcare Pte Ltd",
  "The Little House (Montessori) Kindergarten",
  "The Little Kids House - Vegetarian",
  "The Little Language League",
  "The Little Skool-House",
  "The Little Skool-House @ Kent Vale Pte. Ltd.",
  "The Little Skool-House International Pte Ltd",
  "The Living Book (Amk) Pte. Ltd.",
  "The Living Book Schoolhouse Pte. Ltd.",
  "The Mapping Pte Ltd",
  "The Montessori Playroom Kindergarten",
  "The Moral Child Care Centre",
  "The Moral Childcare Centre",
  "The Nurture Cottage Holdings (Childcare)",
  "The Nurture Cottage Holdings Pte. Ltd.",
  "The Orange Tree (Alexandra) Pte. Ltd.",
  "The Orange Tree (Cck) Pte. Ltd.",
  "The Orange Tree (Simei) Pte. Ltd.",
  "The Orange Tree Preschool",
  "The Orange Tree Preschool",
  "The Playground Preschool Pte Ltd",
  "The Preparatory Place",
  "The Pre-Schoolers Childcare & Development Centre Pte Ltd",
  "The Pre-Schoolers Learning Centre Pte Ltd",
  "The Salvation Army Ang Mo Kio Child Care Centre",
  "The Salvation Army Bukit Batok East Child Care Centre",
  "The Salvation Army Bukit Panjang Child Care Centre",
  "The Salvation Army Tampines Child Care Centre",
  "The School Of Make-up Pte Ltd",
  "The Singapore Teachers' Co-Op Child Care Centre",
  "The Singapore Teochews Co-Op Child Care Centre",
  "The Sunbird Child Development Centre Pte Ltd",
  "The Word Learning Centre Pte Ltd",
  "Thinkerstar Campus Pte. Ltd.",
  "ThinkerStar Educare / Campus",
  "Thinkerstar Educare Pte Ltd",
  "Thomson Road Baptist Kindergarten",
  "Thy Little Kingdom Pte. Ltd.",
  "Thye Hua Kwan Educare Centre (Ang Mo Kio)",
  "Toa Payoh Methodist Church",
  "Toa Payoh Methodist Church Kindergarten",
  "Toddlers' Cottage",
  "Toni & Guy Academy Pte Ltd",
  "Tony Buzan Learning Centre Pte Ltd",
  "Tots Club Preschool",
  "Tots House Child Care Centre & Pre School",
  "Totsville Montessori Centre",
  "Touch Child Care & Family Centre",
  "Touch Child Care & Family Centre (Clementi)",
  "Touch Child Care & Family Centre (Hougang)",
  "Touch Community CC",
  "Townsville Primary School",
  "TPBC Kindergarten",
  "Treasure Box Child Development Centre Pte Ltd",
  "Treasure Box Child Development Centre Pte Ltd",
  "Trinity Methodist Church Kindergarten",
  "True Way Presbyterian Kindergarten",
  "Tuffatia Reader Nichie Kindergarten",
  "Tung Ling Kindergarten",
  "Twinkle Child Care And Development Centre",
  "Twinkle Kids Pre-School Pte Ltd",
  "Twinkle Kids Pre-School Pte. Ltd.",
  "Twinkle Mind Childcare",
  "Twinklekidz Academy Pte. Ltd.",
  "Twinks School House",
  "Two By Two Schoolhouse",
  "Ubi Child Care & Development Centre",
  "Uni-3 Montessori Child Care",
  "United Educare Kindergarten",
  "United World College of South East Asia (UWCSEA)",
  "Unity Primary School",
  "Unity Secondary School",
  "Vether Day Care",
  "Victoria Montessori School",
  "Victoria School",
  "Vithya Junior City",
  "Wee Care @ The Polliwogs Kindergarten",
  "Wee Care Kindergarten",
  "Wellington Primary School",
  "Wesley Child Development Centre",
  "West Coast PCF Education Centre",
  "West Grove Primary School",
  "West Spring Primary School",
  "West Spring Secondary School",
  "West View Primary School",
  "Westwood Primary School",
  "Westwood Secondary School",
  "Wharton Preschool Pte. Ltd.",
  "Whispering Hearts Infant & Child Care Centre",
  "White Lodge Bukit Timah Pte Ltd",
  "White Lodge Education Group Services",
  "White Lodge Kindergarten",
  "White Lodge Kindergarten (East Coast)",
  "White Lodge Kindergarten (Jalan Siap)",
  "White Lodge Kindergarten (Phoenix Park)",
  "White Lodge Kindergarten (South Buona Vista)",
  "White Lodge Kindergarten (Upper East Coast)",
  "White Sand Primary School",
  "White Sands Primary School",
  "Whitley Secondary School",
  "Whiz Kids Montessori Childcare Center",
  "Whiz Kidz Montessori Pte. Ltd.",
  "Whole Child Nurture Centre",
  "Winchester Infant House Pte. Ltd.",
  "Wonder Place Childcare & Development Centre Pte Ltd",
  "Wonder Years Childcare & Development Centre Pte Ltd",
  "Wonder Years Pre School & Child Care Centre",
  "Woodgrove Primary School",
  "Woodgrove Secondary School",
  "Woodlands Primary School",
  "Woodlands Ring Primary School",
  "Woodlands Ring Secondary School",
  "Woodlands Secondary School",
  "Xinghua Primary School",
  "Xingnan Primary School",
  "Xinmin Primary School",
  "Xinmin Secondary School",
  "Xishan Primary School",
  "Xiyao Childcare Pte. Ltd.",
  "Yangzheng Primary School",
  "Yasmine @ Frankel Early Learning Centre",
  "Yew Tee Primary School",
  "Yio Chu Kang Chapel Kindergarten",
  "Yio Chu Kang Primary School",
  "Yio Chu Kang Secondary School",
  "Yishun Primary School",
  "Yishun Secondary School",
  "Yishun Town Secondary School",
  "Ykidz Montessori",
  "YMCA Child Development Centre",
  "YMCA Child Development Centre / Kindergarten",
  "YMCA Child Development Centre-Bukit Batok",
  "Young Elite Childcare & Development Pte Ltd",
  "Young Kids Child Care",
  "Young Minds Childcare Centre",
  "Young Minds Childcare Centre",
  "Young Talents Childcare & Development Centre",
  "Young Talents Preschool Pte. Ltd.",
  "Young Talents Preskool Pte Ltd",
  "Yu Neng Primary School",
  "Yuan Ching Secondary School",
  "Yuhua Primary School",
  "Yuhua Secondary School",
  "Yumin Primary School",
  "Yuquan Bilingual Preschool Pte. Ltd.",
  "Yuquan Language School",
  "Yuquan Preschool",
  "Yuquan Preschool",
  "Yuquan Preschool @Guillemard",
  "Yusof Ishak Secondary School",
  "Yuvabharathi International School",
  "Yuying Secondary School",
  "Ywca Ang Mo Kio Child Development Centre",
  "Ywca Bedok South Child Development Centre",
  "Ywca Bishan Child Development Centre",
  "Ywca Bukit Gombak Child Development Centre",
  "Ywca Jurong East Child Development Centre",
  "YWCA Kindergarten",
  "YWCA Kindergarten (Outram)",
  "Ywca Marine Drive Child Development Centre",
  "Ywca Mcnair Road Child Development Centre",
  "Ywca Outram Child Development Centre",
  "Ywca Pasir Ris Child Development Centre",
  "YWCA West Coast Child Development Centre",
  "YY Childcare Centre Pte. Ltd.",
  "Zee Juniors",
  "Zee Playhouse Pte Ltd",
  "Zee Schoolhouse",
  "Zee Schoolhouse Pte Ltd",
  "Zhangde Primary School",
  "Zhenghua Primary School",
  "Zhenghua Secondary School",
  "Zhonghua Primary School",
  "Zhonghua Secondary School",
  "Zion Bishan Kindergarten",
  "Zion Child Care & Development Centre Pte Ltd",
  "Zion Kindergarten",
  "Zoo-Phonics Kindergarten",
  "Zulfa Kindergarten",
  "Zulfa Kindergarten @ Choa Chu Kang",
  "Zulfa Kindergarten @ Pasir Ris",
  "Zulfa Kindergarten @ Sembawang Mart",
  "Precious Kids",
  "HDB Hub, 480 Lor 6 Toa Payoh , 3Rd Storey,Learning And Leisure Centre",
  "PCF @ Ayer-Rajah - K+ By The Reservoir Child Development Centre",
  "PCF @ Bukit Gombak Sparkletots Infant Care & Child Care Centre",
  "PCF @ Changi Simei - K+ @ Blk 233 Changi Simei Child Development Centre",
  "PCF @ Fengshan Sparkletots Infant And Child Care Centre",
  "PCF @ Fengshan Sparkletots Infant And Childcare Centre At Blk 115",
  "PCF @ Jalan Kayu - K+ @ Jalan Kayu Child Development Centre",
  "PCF @ Kampong Ubi-Kembangan - K+ @ Eunos Village Child Development Centre",
  "PCF @ Kreta Ayer-Kim Seng K+ @Indus Gardens Child Development Centre",
  "PCF @ Little Wings Bukit Timah Childcare Centre (Blk 305)",
  "PCF @ Little Wings Jurong Central Childcare Centre Blk 404",
  "PCF @ Little Wings Jurong Spring Childcare Centre Blk 553",
  "PCF @ Marsiling - K+ Brain Builders Child Development Centre",
  "PCF @ Moulmein-Tai Pei Childcare And Development Centre",
  "PCF @ Punggol Central - K+ @ Arts Circle Child Development Centre",
  "PCF @ Punggol East Sparkletots Infant & Child Care Centre",
  "PCF @ Sparkletots Child Care Centre (Tampines Changkat)",
  "PCF @ Woodlands Sparkletots Infant & Child Care Centre",
  "AceKidz @ Work (Choa Chu Kang)",
  "AceKidz Group (Hougang)",
  "Acension Kindergarden",
  "Al - Amin Pre-School / Kindergarten",
  "Alsagoff Arab School",
  "Kindercare Learning Centre",
  "Ancension Kindergarten",
  "Calvary Baptist Kindergarten",
  "Learning Vision @ Changi Airport",
  "Cam Kindergarden",
  "Canossa Convent High School",
  "Cedan Child Dert Centre",
  "CHIJ St. Nicholas Girls' School (Secondary)",
  "Christian Gospel Mission Kindergarten",
  "Covenur kindergarden",
  "Madrasah Wak Tanjong Al-Islamiah",
  "Jay Cathoul High School",
  "Korean International School",
  "Learning Vision @ Changi Business Park",
  "Learning Vision @ Nanyang Technological University",
  "Science Park II Kinder Corner",
  "Learning Vision @ Raffles Place",
  "Lycee Francais School",
  "Learning Vision @ Tan Tock Seng Hospital",
  "Learning Vision @ The Grassroots Club",
  "Little Skool House @ By the lake",
  "Little Skool House @ OCC",
  "Little Skool House Tampines",
  "Macpherson Kindergarten",
  "Madrasah Al Sagoff Al-Arabiah",
  "Maha Bodhi Kindergarten",
  "Mavis kindergarten",
  "MMI Preschool & Childcare @ Katong",
  "MMI Preschool & Childcare @ Kovan",
  "MOE Kindergarten @ Punggol View",
  "Moral Child Development Centre",
  "National Public School",
  "Newton Kindergarten",
  "Not Disclosed",
  "PCF @ East Coast",
  "PCF @ Jurong East",
  "PCF @ Teban Gardens",
  "PCF @ Tiong Bahru",
  "PCF @ Yishun",
  "PCF @ Yishun 737",
  "PCG 3 in 1",
  "Seeds Kindergarden",
  "Play @ Tampines",
  "Realkidz Malaysia",
  "Satyasai Kindergarden",
  "Wesley Methodist kindergarten",
  "Kinderland Preschool (Sengkang)",
  "My First Skool @ Toa Payoh Centre",
  "YMCA Child Development Centre-Yishun",
  "Citiuids Edventure Child Care",
  "Saiyi Sai Kindergarten",
  "Kaki Bukit Kindergarten",
  "Columbia Learning Centre",
  "Praises Kids Academy",
  "San Yu Adventist School",
  "Averbel Child Development Centre Pte Ltd",
  "The Japanese Primary School Clementi Campus",
  "Northoaks Primary School",
  "Jeet's Little Learners (Bedok) Pte Ltd",
  "Canossian School",
  "Ang Mo Kio Methodist Church Kindergarten",
  "3House Learning Centre (Pasir Ris)",
  "PCF Sparkletots Preschool @ Bishan North Blk 219",
  "Oasis Primary School",
  "Telok Kurau Primary School",
  "My Prep School",
  "Kidzone Childcare Centre @ Chong Pang Yishun",
  "Kidzone Childcare Centre @ Chong Pang Yishun",
  "First Skool Parkway Parade",
  "Brighton Montessori Frankel Avenue",
  "Angsana Primary School",
  "Global Indian International School",
  "Rosyth Primary School",
  "St. Nicholas Girl School",
  "Irsyad Primary School",
  "Kindergarten @ Fernvale Link",
  "Montford Junior School",
  "PAP @ 436 Fernvale",
  "PCF (Cheng Sau - Seletar)",
  "Tzu Chi Great Love Pre School",
  "Raffles Kidz International",
  "PAP",
  "MOE @ Seng Kang Primary",
  "Berry Tree Preschool",
  "Cambridge Yio Chu Kang",
  "MMI @ North Star",
  "Hamilton Pre-school",
  "Maria Schoolhouse Childcare",
  "The People's Bible Church Kindergarten",
  "Ghusram kindergarten",
  "PCF @ Anchorvale Blk 303A",
  "ACS Junior",
  "Punggol Cove Primary School",
  "Life Society Child Care",
  "Elfa Child Development Centre",
  "Ngee Ann Polytechnic",
  "The Japanese Secondary School",
  "Madrasah Irsyad Zuhri Al Islamiah",
  "Sparkle Tots Kindergarten",
  "PCF Sparkle Tots Pre Sch @ Sengkang West Blk 441B",
  "Khalsha Kindergarten",
  "PCF @ Sengkang West (Fernvale)",
  "Changi General Hospital Childcare",
  "St Gabriel",
  "Balestier Primary School",
  "MOE Kindergarten - Punggol Green",
  "Nurture Stars @ SAFRA Country Club",
  "Kinderland Republic Poly",
  "PCF Sparkletots Preschool at Cashew Blk 545",
  "SFA EduHearts",
  "E-Bridge Pre-School @ Yishun",
  "SSTC Institute",
  "Kinder Care @Joo Seng",
  "Skool4kidz Preschool",
  "Skool4kidz Preschool",
  "PAP Community Foundation - Punggol North 171C-171B",
  "MY FIRST SKOOL (183 EDGEFIELD PLAINS)",
  "Dover Court International School",
  "Mind Stretcher",
  "Madrasah Al-Arabiah Al-Islamiah .",
  "Monfort Secondary School",
  "Waterway Primary School",
  "PCF Sparkletots Preschool @ Sengkang West Blk 330A",
  "PCF @ Fernvale Riverbow",
  "PCF Sparkletots Preschool @ Sengkang Central Blk 231 (CC)",
  "Capstone Kindergarten",
  "MOE Kindergarten @ West Spring",
  "Brainy Bunch Montessor",
  "Living Sanctuary Church Kindergargarten",
  "Sengkang Methodist Kindergarten",
  "LSK Kindergarten",
  "PAP Community Foundation - Paya Lebar",
  "MOE Kindergarten @ Westwood",
  "Lucas Children Academy",
  "ACS International Singapore",
  "MOE Kindergarten @ Sengkang Green",
  "Coleman College",
  "PCF@Hougang",
  "The Berry Tree",
  "Skool4Kidz @ Yishun Centre",
  "Raffles Girl's Primary School",
  "Sri Satya Sai Kindergarten",
  "Teck Ghee Primary School",
  "Alexandra Primary School",
  "Dimensions International College",
  "PCF @ Gangsa Road Blk 128",
  "The Little School House",
  "Little Castle",
  "Stellar Montessori Pte Ltd",
  "Greenland",
  "E-Bridge Pre-School @ Bukit Panjang",
  "Sengkang Primary School",
  "The Glitter Rainbow Skool",
  "PCF @ SPARKLETOTS PRESCHOOL PUNGGOL NORTH Blk 171C",
  "PCF @ Dover",
  "PCF Sparkletots Preschool @ Zhenghua Blk 632A",
  "Tembusu @ Hokey Pokey",
  "Paya Lebar Methodist Church (PLMC) Covenant Kindergarten",
  "Heritage Academy",
  "AlSagoff Arabic School",
  "Yang Zheng Pri Sch",
  "CHIJ (Katong) Primary School",
  "Sparkletots @AMK",
  "PAP Sparkletots",
  "Irsyad Zuhri Al-lslamiah",
  "The Purple Train Learning Station.",
  "Madrasah Al-Maarif Al-Islamiah",
  "An-Nur Mosque Kindergarten",
  "Kinderland",
  "Mee Toh",
  "PCF @ Sparkletots",
  "Small Wonder Child Care",
  "Gems World Academy",
  "My World @ Compassvale Ancilla",
  "The Open Primary school",
  "Posso Preschool @ Big Box Pte Ltd",
  "MRC Kindergarten - Yishun South",
  "Creative O Preschool",
  "Fern Green Primary school",
  "Meridian Secondary School",
  "PCF @ Sparkletots Ulu Pandan [Blk 23]",
  "PCF SPARKLETOTS PRESCHOOL @ PUNGGOL NORTH BLK 172C",
  "Chng Zheng Primary",
  "MOE Kindergarten @ Springdale (SengKang)",
  "E-Bridge Preschool",
  "Learning Vision@NYP",
  "Merlion Kids",
  "Pierce Secondary School",
  "Aaytozee @ Hillview Pte Ltd",
  "St Joseph Independent",
  "Elite Kindergarten",
  "My world preschool",
  "PCF Sparkletots Preschool @ Bukit Timah Blk 319 (KN), 319 Clementi Avenue 4 1203",
  "Meetoh School",
  "Yuvabharathi International School",
  "Carpe Diem Young Joy",
  "British Council Kindergarten",
  "Harmoni PreSkool",
  "Redpony Centre Cashew",
  "Eshkol Valley @ Northstar",
  "Dear Students Morning School",
  "Elsa Pre School",
  "NPS International",
  "Maple Bear Preschool",
  "Chengzhu Mandarin Kindergarten",
  "Sunflower Preschool @ Gombak",
  "Moppeteers Private School",
  "Bukit Panjang Methodist Church Kindergarten",
  "Eskhol Valley Preschool",
  "7oaks Preschool",
  "PCS Bukit Batok",
  "Raffles kidz",
  "Cambridge @ Sengkang",
  "Little Seeds",
  "Sunflower Preschool",
  "Caelum Junior",
  "Hallway International School",
  "Small Wonder",
  "Children's Cove Pre school",
  "The Olive Tree",
  "St Andrew's Mission School",
];
