import {
  Input,
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";
import {
  checkboxAnatomy,
  inputAnatomy,
  radioAnatomy,
  selectAnatomy,
} from "@chakra-ui/anatomy";
import "@fontsource-variable/outfit";

// For custom button variant
const buttonTheme = defineStyleConfig({
  variants: {
    "ms-solid": {
      color: "white",
      backgroundColor: "#013768",
      _hover: { backgroundColor: "#002B52" },
      px: 6,
      py: 3,
      borderRadius: "8px",
      _loading: {
        _hover: { backgroundColor: "#002B52" }, // otherwise by default it will become a light color
      },
    },
    "ms-outline": {
      color: "#013768",
      fontWeight: 500,
      borderWidth: "2px",
      borderColor: "#013768",
      _hover: { backgroundColor: "gray.100" },
      px: 6,
      py: 3,
      borderRadius: "8px",
    },
  },
});

// For custom input variant
const {
  definePartsStyle: definePartStyleInput,
  defineMultiStyleConfig: defineMultiStyleConfigInput,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputBaseStyle = definePartStyleInput({
  field: {
    backgroundColor: "white !important",
  },
});

const inputTheme = defineMultiStyleConfigInput({
  baseStyle: inputBaseStyle,
});

// For custom radio varaint
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const cardStyle = definePartsStyle({
  control: {
    display: "none",
  },
  label: {
    border: "1px solid #E1E1E1",
    borderRadius: "0.625rem",
    px: 3,
    py: 2,
    ml: 0,
    _checked: {
      border: "1px solid #013768",
      backgroundColor: "#013768",
      color: "white",
      fontWeight: 600,
    },
  },
});

const radioTheme = defineMultiStyleConfig({
  variants: {
    "card-variant": cardStyle,
  },
});

// For custom select component
const {
  definePartsStyle: definePartStyleSelect,
  defineMultiStyleConfig: defineMultiStyleConfigSelect,
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const selectBaseStyle = definePartStyleSelect({
  field: {
    backgroundColor: "white !important",
  },
});

const selectTheme = defineMultiStyleConfigSelect({
  baseStyle: selectBaseStyle,
});

// For custom checkbox component

const {
  definePartsStyle: definePartsStyleCheckbox,
  defineMultiStyleConfig: defineMultiStyleConfigCheckbox,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkbox4pxStyle = definePartsStyleCheckbox({
  container: {
    alignItems: "flex-start",
  },
  control: {
    marginTop: "4px", // to make the checkbox line up with height of first line
  },
});

const checkboxTheme = defineMultiStyleConfigCheckbox({
  variants: {
    "checkbox-4px": checkbox4pxStyle,
  },
});

// For custom font
const theme = extendTheme({
  fonts: {
    heading: `'Outfit Variable', sans-serif`,
    body: `'Outfit Variable', sans-serif`,
  },
  components: {
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Input: inputTheme,
    Radio: radioTheme,
    Select: selectTheme,
  },
});

export default theme;
