import React from "react";
import "./DataProtectionPolicyPage.css";

export const DataProtectionPolicyPage = () => (
  <div className="data-protection-main">
    <h1>MS Personal Data Protection Policy</h1>

    <p>
      The Mind Stretcher Group of Companies, which consists of any entity
      operating under the "Mind Stretcher", "Mind Stretcher Learning Centre",
      "MS Junior Campus", "Mind Stretcher Junior Campus", "Xue Hui Le", "学 汇
      乐", Master Speakers names or any of their variations thereof in Singapore
      (hereinafter referred to as "the MS Group", "MS", "Mind Stretcher", "our",
      ''us" or "we"), is committed to ensuring that your personal data is
      protected.
    </p>

    <p>
      The purpose of this document is to explain how the MS Group manages
      personal data which is subject to the Singapore Personal Data Protection
      Act 2012 ("PDPA"). All references in this Policy to "client", "you" and
      "your" refer to the individual(s) who accepted or proposes to accept
      products or services by MS.
    </p>

    <p>
      For the purposes of this policy, "Personal Data" shall carry the
      definition assigned to it under the PDPA, and shall include any data about
      you, the Parents or Student, who can be identified from that data and/or
      other information to which MS has or is likely to have access to.
    </p>

    <ol>
      <li>Types of personal data collected</li>
      <p>
        We collect personal data so that we can provide an experience that is
        customised / responsive to your needs and enables us to carry out our
        business activities. Examples of such personal data collected include:
      </p>
      <ol type="a">
        <li>
          Personal information about you and your family members, which may
          include names, contact details, preferences, educational information,
          demographic data, general photos/videos of classroom activities / MS
          events (in which a student may be enrolled in) organised by us, health
          information, payment details and transaction history;
        </li>
        <li>
          Information and data generated in the course of any continual
          relationship with MS, for example, instructions given by you to
          arrange class enrolments, transfers, replacements / make-up (if any),
          withdrawals, and other information and records you choose to provide
          by various modes of communication such as email, telephone, SMS,
          telephone, social media, market research and the internet; and
        </li>
        <li>
          Information from cookies, technologies deployed by Mind Stretcher for
          the analysis of website usage or other information technology
          applications (learning platforms or otherwise) used by MS in
          delivering or communicating its services.
        </li>
      </ol>

      <p>
        MS may also collect video footage, still images, or audio recordings of
        you ("Audio Visual Material") recorded in the course of any classes,
        webinars, programmes or lessons conducted, or other services rendered by
        a MS entity, its staff or contractors. The Audio Visual Material form
        part of the Personal Data collected by Mind Stretcher.
      </p>

      <li>Purpose of Collection, Use and Disclosure of Personal Data</li>
      <p>
        We collect personal data from you, your family members, your authorised
        representatives, our website and social media, mobile and online
        services, emails, SMS, phone conversations, face-to-face interactions
        with any Mind Stretcher employee/officer/director and publicly available
        sources.
      </p>

      <p>
        The purposes ("Permitted Purposes") for which Mind Stretcher collects,
        uses and discloses the personal data include:
      </p>
      <ol type="a">
        <li>verifying your identity;</li>
        <li>discharging our contractual obligations;</li>
        <li>
          maintaining accurate client information and enable customised
          experiences that meet your preferences and needs;
        </li>
        <li>
          providing, operating and administering MS Group products and services,
          or processing applications for MS Group products and services,
          business or financial transactions, and to maintain service quality
          levels and train staff;
        </li>
        <li>
          facilitating and administering the services enrolled by you, including
          but not limited to providing you with learning or educational material
          / services;
        </li>
        <li>
          providing you product-related services and support, including the
          provision of administrative support, technical assistance and customer
          alerts;
        </li>
        <li>
          managing MS Group's relationship with you, which may include providing
          information on MS Group's products and services, where specifically
          consented to or where permissible under applicable laws and
          regulations;
        </li>
        <li>
          sending you marketing information about our services, including but
          not limited to cross-marketing between MS Group entities and notifying
          you of our marketing events, initiatives, and promotions;
        </li>
        <li>
          for use in promotional, branding and marketing material distributed by
          MS Group whether online or offline, including but not limited to posts
          on social media, website, print or electronic copies of newsletters
          and brochures, promotional video advertisements and print
          advertisements;
        </li>
        <li>
          facilitating product development and service refinements via customer
          feedback, data analysis and market research;
        </li>
        <li>
          performing internal management, operating management information
          systems, carrying out and enabling internal and external audits;
        </li>
        <li>
          monitoring and recording calls and electronic communications with
          clients for record-keeping, quality control, training and case
          investigations;
        </li>
        <li>
          managing the administrative and operational aspects of the MS Group
          and complying with internal policies and procedures;
        </li>
        <li>
          facilitating operational processes, which includes, but not limited to
          student profile assessments, class and student management, payment
          administration and statistical analysis;
        </li>
        <li>
          requesting feedback or participation in surveys (on teachers, staff
          etc), market research and / or analysis for statistical profiling or
          other purposes for us to design our products, understand customer
          behaviour, preference and market trends, and to review, develop and
          improve the quality of our products and services;
        </li>
        <li>
          managing the safety and security of our premises and services
          (including but not limited to carrying out CCTV surveillance and
          conducting investigations);
        </li>
        <li>
          monitoring and recording calls and electronic communications with
          clients for record-keeping, quality control, training and case
          investigations;
        </li>
        <li>
          enforcing or defending the rights of MS, its employees, officers and
          directors;
        </li>
        <li>
          complying with any applicable laws, regulations, codes of practice,
          guidelines, or rules or to assist in law enforcement and
          investigations conducted by any governmental and/or regulatory
          authority;
        </li>
        <li>for safety, legal and regulatory compliance; and</li>
        <li>
          any other incidental business purpose related to or in connection with
          the above.
        </li>
      </ol>

      <li>To whom your personal data may be disclosed</li>
      <p>
        The personal data that we collect may be disclosed to the following
        parties for the following purposes:
      </p>
      <ol type="a">
        <li>
          to our teachers for them to effectively discharge their teaching and
          tutoring obligations;
        </li>
        <li>
          to third party service providers whom we engage or collaborate with to
          organise/conduct our enrichment programmes, events / workshops / talks
          / seminars;
        </li>
        <li>
          any person who is or any entity which is part of Mind Stretcher, to
          the extent necessary to fulfil the Permitted Purposes;
        </li>
        <li>
          to professional advisors, third party service providers, agents or
          contractors whom we engage to manage / support our business
          operations; and
        </li>
        <li>
          any person, entity or authority to whom disclosure is required under
          applicable laws or regulations.
        </li>
      </ol>

      <li>Security of Personal Data</li>
      <p>
        We will take reasonable steps to protect personal data in our possession
        or under our control by making reasonable security arrangements to
        prevent unauthorised access, collection, use and disclosure of these
        personal data. Personal Data may be transmitted by MS or by a MS entity
        to data storage facilities or third party service providers outside
        Singapore. Regardless of the location where Personal Data is transferred
        or stored, MS or the relevant MS entity will make reasonable security
        arrangements to prevent unauthorised access, collection, use,
        disclosure, copying, modification, disposal or similar risks to your
        Personal Data. Please note, however, that we cannot be held responsible
        for unauthorised or unintended use, access or disclosure that is beyond
        our control.
      </p>

      <li>Accuracy of Personal Data</li>
      <p>
        It is your obligation to ensure that all personal data submitted to us
        and retained by us is accurate, not misleading, updated and complete in
        all respects. Failure on your part to do so may result in our inability
        to provide you with the products and services you have requested. For
        the avoidance of doubt, we, including our employees or authorised
        officers or agents, shall not be responsible for any personal data
        submitted by you to us that may be inaccurate, misleading, incomplete or
        not updated.
      </p>

      <p>
        If you provide us with any personal data relating to a third party (e.g.
        information of your spouse, children, parents, and / or guardians), by
        submitting such information to us, you represent to us that you have
        obtained the consent of the third party to provide us with their
        personal data for the respective purposes. You should ensure that all
        personal data submitted to us is complete, accurate, true and correct.
        Failure on your part to do so may result in our inability to provide you
        with the products and services you have requested.
      </p>

      <li>Retention of Personal Data</li>
      <p>
        We will retain your personal information either for the period of our
        business relationship, or as long as you have any form of ongoing
        relationship with us, or for the requisite retention periods as
        stipulated in any contractual arrangements or in accordance with any
        legal, regulatory, business and operational obligations, whichever is
        the later.
      </p>

      <li>Access and Correction of Personal Data</li>
      <p>
        You have the right to request a copy of your Personal Data held by us
        and to request for any inaccurate Personal Data to be rectified. A
        request can be submitted in writing, by mail or email. Please note that
        we may be prevented by law from complying with any request that you may
        make. We may also decline any request that you may make if the law
        permits us to do so. MS reserves the right to charge an administrative
        fee for providing a copy of your Personal Data. If a fee is to be
        charged, we will inform you of the amount beforehand and respond to your
        request after payment is received.
      </p>

      <li>Personal Data Protection Policy Updates</li>
      <p>
        We reserve the right to amend and vary the terms of this Policy from
        time to time. If so, we will make available the updated Policy on our
        website (www.mindstretcher.com). The use or continued use of any of our
        services shall be deemed as your agreement and consent to be bound by
        our Personal Data Protection Policy.
      </p>

      <li>Questions and Feedback</li>
      <p>
        For any queries, you may email us at{" "}
        <a href="mailto:enquiries@mindstretcher.com">
          enquiries@mindstretcher.com
        </a>
        . (Attention: Data Protection Officer).
      </p>
    </ol>

    <p>
      By providing to us your personal data, you declare that you have read,
      understood and accepted the provisions herein and do hereby consent to the
      processing of your personal data in accordance with all the foregoing. You
      acknowledge that the use or continued use of our services shall be deemed
      as your agreement and consent to be bound by our Personal Data Protection
      Policy.
    </p>
  </div>
);
