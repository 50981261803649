import { Box } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box
      width="100%"
      px={{ base: 6, md: 20 }}
      py={{ base: 6, md: 16 }}
      borderRadius="20px"
      backgroundColor="#F7F9FF"
      mt={12}
      mb={16}
    >
      {children}
    </Box>
  );
}

export default PagedFlowLayout;
