// Copied from /shared folder
// A Singapore unit number should be in the form of eg. #03-01
export const isValidSgUnitNumber = (value: unknown) => {
  if (typeof value !== "string") {
    return false;
  }

  // Check if the input starts with '#' and contains '-'
  if (!value.startsWith("#") || !value.includes("-")) {
    return false;
  }

  // Remove the leading '#' and split the string by '-'
  const [firstPart, secondPart] = value.slice(1).split("-");

  // Validate the first part: should be 2 to 3 digits and be made of numbers only
  if (!/^\d{2,3}$/.test(firstPart)) {
    return false;
  }

  // Validate the second part: should be 2 to 5 characters long
  if (secondPart.length < 2 || secondPart.length > 5) {
    return false;
  }

  return true;
};
