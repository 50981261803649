import "./App.css";

import { Box, ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import FormHeader from "./lib/components/FormHeader";
import _ from "lodash";
import RegistrationForm from "./RegistrationForm";
import SuccessPage from "./SuccessPage";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";
import { DataProtectionPolicyPage } from "./DataProtectionPolicyPage";

function App() {
  let pageToRender;
  switch (window.location.pathname) {
    case "/terms-and-conditions":
      pageToRender = <TermsAndConditionsPage />;
      break;
    case "/data-protection-policy":
      pageToRender = <DataProtectionPolicyPage />;
      break;
    case "/success":
      pageToRender = <SuccessPage />;
      break;
    default:
      // TODO: Replace with /404 next time
      pageToRender = <RegistrationForm />;
  }

  return (
    <ChakraProvider theme={theme}>
      <Box className="App">
        <FormHeader />
        {pageToRender}
      </Box>
    </ChakraProvider>
  );
}

export default App;
