import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayoutNavSection({
  mt,
  children,
}: {
  mt?: number;
  children: React.ReactNode;
}) {
  return (
    <HStack w="100%" justifyContent="flex-end" mt={mt ?? 24} gap={4}>
      {children}
    </HStack>
  );
}

export default PagedFlowLayoutNavSection;
